import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { ThongBaoDienThoai } from 'src/app/shared/ThongBaoDienThoai.model';
import { ThongBaoDienThoaiService } from 'src/app/shared/ThongBaoDienThoai.service';

@Component({
  selector: 'app-thong-bao-dien-thoai',
  templateUrl: './thong-bao-dien-thoai.component.html',
  styleUrls: ['./thong-bao-dien-thoai.component.css']
})
export class ThongBaoDienThoaiComponent implements OnInit {

  @ViewChild('ThongBaoDienThoaiSort') ThongBaoDienThoaiSort: MatSort;
  @ViewChild('ThongBaoDienThoaiPaginator') ThongBaoDienThoaiPaginator: MatPaginator;


  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  
  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,

    public ThongBaoDienThoaiService: ThongBaoDienThoaiService,

  ) { }


  ngOnInit(): void {
    this.ThongBaoDienThoaiSearch();
  }


  DateBatDau(value) {
    this.ThongBaoDienThoaiService.baseParameter.BatDau = new Date(value);
  }
  DateKetThuc(value) {
    this.ThongBaoDienThoaiService.baseParameter.KetThuc = new Date(value);
  }


  DateThongBaoDienThoaiBatDau(element: ThongBaoDienThoai, value) {
    element.BatDau = new Date(value);
  }
  DateThongBaoDienThoaiKetThuc(element: ThongBaoDienThoai, value) {
    element.KetThuc = new Date(value);
  }


  fileToUpload: any;
  fileToUpload0: File = null;
  changeFileName(element: ThongBaoDienThoai, files: FileList) {
    if (files) {
      this.fileToUpload = files;
      this.fileToUpload0 = files.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        element.FileName = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload0);
    }
  }
  ThongBaoDienThoaiSearch() {
    if (this.ThongBaoDienThoaiService.SearchString.length > 0) {
      this.ThongBaoDienThoaiService.DataSource.filter = this.ThongBaoDienThoaiService.SearchString.toLowerCase();
    }
    else {
      this.ThongBaoDienThoaiGetToList();
    }
  }
  ThongBaoDienThoaiGetToList() {
    this.isShowLoading = true;
    this.ThongBaoDienThoaiService.GetAllAndEmptyToListAsync().subscribe(
      res => {
        this.ThongBaoDienThoaiService.list = (res as ThongBaoDienThoai[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ThongBaoDienThoaiService.DataSource = new MatTableDataSource(this.ThongBaoDienThoaiService.list);
        this.ThongBaoDienThoaiService.DataSource.sort = this.ThongBaoDienThoaiSort;
        this.ThongBaoDienThoaiService.DataSource.paginator = this.ThongBaoDienThoaiPaginator;

        this.ThongBaoDienThoaiService.list001 = this.ThongBaoDienThoaiService.list.slice(1);
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );    
  }

  ThongBaoDienThoaiSave(element: ThongBaoDienThoai) {
    this.isShowLoading = true;
    this.ThongBaoDienThoaiService.formData = element;
    this.ThongBaoDienThoaiService.fileToUpload = this.fileToUpload;
    console.log("this.ThongBaoDienThoaiService.fileToUpload:",this.ThongBaoDienThoaiService.fileToUpload);
    this.ThongBaoDienThoaiService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.fileToUpload = null;
        this.fileToUpload0 = null;
        this.ThongBaoDienThoaiSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }



  ThongBaoDienThoaiDelete(element: ThongBaoDienThoai) {
    if (confirm(environment.DeleteConfirm)) {
      this.isShowLoading = true;
      this.ThongBaoDienThoaiService.baseParameter.ID = element.ID;
      this.ThongBaoDienThoaiService.RemoveAsync().subscribe(
        res => {
          this.ThongBaoDienThoaiSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }

}
