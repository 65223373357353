import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { DanhMucDuAn } from 'src/app/shared/DanhMucDuAn.model';
import { DanhMucDuAnService } from 'src/app/shared/DanhMucDuAn.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienDetail001Component } from '../thanh-vien-detail001/thanh-vien-detail001.component';




@Component({
  selector: 'app-thanh-vien002',
  templateUrl: './thanh-vien002.component.html',
  styleUrls: ['./thanh-vien002.component.css']
})
export class ThanhVien002Component implements OnInit {

  @ViewChild('ThanhVienSort') ThanhVienSort: MatSort;
  @ViewChild('ThanhVienPaginator') ThanhVienPaginator: MatPaginator;


  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,

    public DanhMucDuAnService: DanhMucDuAnService,

    public ThanhVienService: ThanhVienService,
  ) { }

  ngOnInit(): void {
    this.DanhMucDuAnGetToList();
  }


  DanhMucDuAnGetToList() {
    

    this.ThanhVienService.GetLogin();
    if (this.ThanhVienService.formDataLogin.ID > 0) {
      this.ThanhVienService.baseParameter.ID = this.ThanhVienService.formDataLogin.ID;
      this.ThanhVienService.GetByIDAsync().subscribe(
        res => {
          this.ThanhVienService.formData = res as ThanhVien;

          this.DanhMucDuAnService.baseParameter.ID = this.ThanhVienService.formData.DanhMucDuAnID;
          this.DanhMucDuAnService.GetByIDToListAsync().subscribe(
            res => {
              this.DanhMucDuAnService.list = (res as DanhMucDuAn[]);
            },
            err => {
            }
          );

        },
        err => {
        }
      );
    }
  }

  ThanhVienSearch() {
    this.ThanhVienGetToList();
  }
  ThanhVienGetToList() {
    this.isShowLoading = true;    
    this.ThanhVienService.GetByDanhMucDuAnIDAndSearchStringToListAsync().subscribe(
      res => {
        this.ThanhVienService.list = (res as ThanhVien[]).sort((a, b) => (a.CreatedDate < b.CreatedDate ? 1 : -1));
        this.ThanhVienService.DataSource = new MatTableDataSource(this.ThanhVienService.list);
        this.ThanhVienService.DataSource.sort = this.ThanhVienSort;
        this.ThanhVienService.DataSource.paginator = this.ThanhVienPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ThanhVienAdd(ID: any) {
    this.ThanhVienService.baseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.formData = res as ThanhVien;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienDetail001Component, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ThanhVienSearch();
        });
      },
      err => {
      }
    );
  }
}