import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucChucDanh } from 'src/app/shared/DanhMucChucDanh.model';
import { DanhMucChucDanhService } from 'src/app/shared/DanhMucChucDanh.service';
import { DanhMucChungChi } from 'src/app/shared/DanhMucChungChi.model';
import { DanhMucChungChiService } from 'src/app/shared/DanhMucChungChi.service';
import { DanhMucLienKet } from 'src/app/shared/DanhMucLienKet.model';
import { DanhMucLienKetService } from 'src/app/shared/DanhMucLienKet.service';
import { DanhMucSanPham } from 'src/app/shared/DanhMucSanPham.model';
import { DanhMucSanPhamService } from 'src/app/shared/DanhMucSanPham.service';
import { DanhMucTieuChuanSanXuat } from 'src/app/shared/DanhMucTieuChuanSanXuat.model';
import { DanhMucTieuChuanSanXuatService } from 'src/app/shared/DanhMucTieuChuanSanXuat.service';
import { DanhMucThietBi } from 'src/app/shared/DanhMucThietBi.model';
import { DanhMucThietBiService } from 'src/app/shared/DanhMucThietBi.service';
import { DanhMucToChuc } from 'src/app/shared/DanhMucToChuc.model';
import { DanhMucToChucService } from 'src/app/shared/DanhMucToChuc.service';
import { DanhMucDuAn } from 'src/app/shared/DanhMucDuAn.model';
import { DanhMucDuAnService } from 'src/app/shared/DanhMucDuAn.service';



import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { ToChucThanhVien } from 'src/app/shared/ToChucThanhVien.model';
import { ToChucThanhVienService } from 'src/app/shared/ToChucThanhVien.service';
import { ToChucHopTac } from 'src/app/shared/ToChucHopTac.model';
import { ToChucHopTacService } from 'src/app/shared/ToChucHopTac.service';
import { ToChucTieuChuanSanXuat } from 'src/app/shared/ToChucTieuChuanSanXuat.model';
import { ToChucTieuChuanSanXuatService } from 'src/app/shared/ToChucTieuChuanSanXuat.service';
import { ToChucSanPham } from 'src/app/shared/ToChucSanPham.model';
import { ToChucSanPhamService } from 'src/app/shared/ToChucSanPham.service';
import { ToChucKhoThietBi } from 'src/app/shared/ToChucKhoThietBi.model';
import { ToChucKhoThietBiService } from 'src/app/shared/ToChucKhoThietBi.service';
import { ToChucDuAn } from 'src/app/shared/ToChucDuAn.model';
import { ToChucDuAnService } from 'src/app/shared/ToChucDuAn.service';
import { ToChucToaDo } from 'src/app/shared/ToChucToaDo.model';
import { ToChucToaDoService } from 'src/app/shared/ToChucToaDo.service';
import { ToChucQuyTrinhSanXuat } from 'src/app/shared/ToChucQuyTrinhSanXuat.model';
import { ToChucQuyTrinhSanXuatService } from 'src/app/shared/ToChucQuyTrinhSanXuat.service';


import * as maplibregl from 'maplibre-gl';
import { ToChucQuyTrinhSanXuatDetailComponent } from '../to-chuc-quy-trinh-san-xuat-detail/to-chuc-quy-trinh-san-xuat-detail.component';

@Component({
  selector: 'app-to-chuc-detail',
  templateUrl: './to-chuc-detail.component.html',
  styleUrls: ['./to-chuc-detail.component.css']
})
export class ToChucDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  ToChucThanhVienDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucThanhVienSort') ToChucThanhVienSort: MatSort;
  @ViewChild('ToChucThanhVienPaginator') ToChucThanhVienPaginator: MatPaginator;


  @ViewChild('ToChucHopTacSort') ToChucHopTacSort: MatSort;
  @ViewChild('ToChucHopTacPaginator') ToChucHopTacPaginator: MatPaginator;


  @ViewChild('ToChucTieuChuanSanXuatSort') ToChucTieuChuanSanXuatSort: MatSort;
  @ViewChild('ToChucTieuChuanSanXuatPaginator') ToChucTieuChuanSanXuatPaginator: MatPaginator;


  @ViewChild('ToChucSanPhamSort') ToChucSanPhamSort: MatSort;
  @ViewChild('ToChucSanPhamPaginator') ToChucSanPhamPaginator: MatPaginator;


  @ViewChild('ToChucKhoThietBiSort') ToChucKhoThietBiSort: MatSort;
  @ViewChild('ToChucKhoThietBiPaginator') ToChucKhoThietBiPaginator: MatPaginator;

  ToChucDuAnDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucDuAnSort') ToChucDuAnSort: MatSort;
  @ViewChild('ToChucDuAnPaginator') ToChucDuAnPaginator: MatPaginator;

  ToChucToaDoDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucToaDoSort') ToChucToaDoSort: MatSort;
  @ViewChild('ToChucToaDoPaginator') ToChucToaDoPaginator: MatPaginator;

  isShowLoading: boolean = false;
  queryString: string = environment.InitializationString;
  searchString: string = environment.InitializationString;

  constructor(
    private dialog: MatDialog,
    public router: Router,
    public dialogRef: MatDialogRef<ToChucDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,


    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucChucDanhService: DanhMucChucDanhService,
    public DanhMucChungChiService: DanhMucChungChiService,
    public DanhMucLienKetService: DanhMucLienKetService,
    public DanhMucSanPhamService: DanhMucSanPhamService,
    public DanhMucTieuChuanSanXuatService: DanhMucTieuChuanSanXuatService,
    public DanhMucThietBiService: DanhMucThietBiService,
    public DanhMucToChucService: DanhMucToChucService,
    public DanhMucDuAnService: DanhMucDuAnService,




    public ToChucService: ToChucService,
    public ToChucThanhVienService: ToChucThanhVienService,
    public ToChucHopTacService: ToChucHopTacService,
    public ToChucTieuChuanSanXuatService: ToChucTieuChuanSanXuatService,
    public ToChucSanPhamService: ToChucSanPhamService,
    public ToChucKhoThietBiService: ToChucKhoThietBiService,
    public ToChucDuAnService: ToChucDuAnService,
    public ToChucToaDoService: ToChucToaDoService,
    public ToChucQuyTrinhSanXuatService: ToChucQuyTrinhSanXuatService,


  ) {
    // this.router.events.forEach((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.queryString = event.url;
    //     this.ToChucService.formData.ID = parseInt(this.queryString.split("/")[2]);
    //     if (this.queryString) {
    //       if (this.queryString.length > 0) {
    //         this.GetByQueryString();
    //       }
    //     }
    //   }
    // });
  }

  ngOnInit(): void {
    this.DanhMucToChucGetToList();
    this.DanhMucThietBiGetToList();
    this.DanhMucTieuChuanSanXuatGetToList();
    this.DanhMucSanPhamGetToList();
    this.DanhMucLienKetGetToList();
    this.DanhMucChucDanhGetToList();
    this.DanhMucChungChiGetToList();
    this.DanhMucTinhThanhGetToList();

    this.GetAll();
  }

  GetByQueryString() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.IDString = this.queryString
    this.ToChucService.GetByIDStringAsync().subscribe(res => {
      this.ToChucService.formData = res as ToChuc;
      if (this.ToChucService.formData) {
        this.DanhMucToChucGetToList();
        this.DanhMucThietBiGetToList();
        this.DanhMucTieuChuanSanXuatGetToList();
        this.DanhMucSanPhamGetToList();
        this.DanhMucLienKetGetToList();
        this.DanhMucChucDanhGetToList();
        this.DanhMucChungChiGetToList();
        this.DanhMucTinhThanhGetToList();

        this.GetAll();
      }
      this.isShowLoading = false;
    });
  }
  GetAll() {
    this.ToChucGetToList();
    this.ToChucToaDoSearch();
    this.ToChucDuAnSearch();
    this.ToChucKhoThietBiSearch();
    this.ToChucSanPhamSearch();
    this.ToChucTieuChuanSanXuatSearch();
    this.ToChucHopTacSearch();
    this.ToChucThanhVienSearch();
    this.MapLoad();
  }

  ToChucExportHopTacXaByIDToHTMLAsync() {
    this.isShowLoading = true;
    this.DownloadService.baseParameter.ID = this.ToChucService.formData.ID;
    this.DownloadService.ExportHopTacXaByIDToHTMLAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucQuyTrinhSanXuatAdd() {
    this.ToChucQuyTrinhSanXuatService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucQuyTrinhSanXuatService.baseParameter.DanhMucDuAnID = 1;
    this.ToChucQuyTrinhSanXuatService.baseParameter.DanhMucSanPhamID = 890;
    this.ToChucQuyTrinhSanXuatService.GetByParentID_DanhMucDuAnID_DanhMucSanPhamIDAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatService.formData = res as ToChucQuyTrinhSanXuat;
        console.log(this.ToChucQuyTrinhSanXuatService.formData);
        if (this.ToChucQuyTrinhSanXuatService.formData) {
          if (this.ToChucQuyTrinhSanXuatService.formData.ID > 0) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.width = environment.DialogConfigWidth;
            dialogConfig.data = { ID: this.ToChucQuyTrinhSanXuatService.formData.ID };
            const dialog = this.dialog.open(ToChucQuyTrinhSanXuatDetailComponent, dialogConfig);
            dialog.afterClosed().subscribe(() => {
            });
          }
          else {
            this.NotificationService.warn(environment.HTX0001);
          }
        }
        else {
          this.NotificationService.warn(environment.HTX0001);
        }
      },
      err => {
      }
    );
  }

  ToChucToaDoGetToList() {
    this.isShowLoading = true;
    this.ToChucToaDoService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucToaDoService.GetByParentIDAndEmptyToListAsync().subscribe(
      res => {
        this.ToChucToaDoService.list = (res as ToChucToaDo[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucToaDoService.list001 = this.ToChucToaDoService.list;
        this.ToChucToaDoService.DataSource = new MatTableDataSource(this.ToChucToaDoService.list);
        this.ToChucToaDoService.DataSource.sort = this.ToChucToaDoSort;
        this.ToChucToaDoService.DataSource.paginator = this.ToChucToaDoPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucToaDoSearch() {
    if (this.searchString.length > 0) {
      this.ToChucToaDoService.DataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.ToChucToaDoGetToList();
    }
  }
  ToChucToaDoSave(element: ToChucToaDo) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucToaDoService.formData = element;
    this.NotificationService.warn(this.ToChucToaDoService.ComponentSaveByParentID(this.ToChucToaDoSort, this.ToChucToaDoPaginator));
  }
  ToChucToaDoDelete(element: ToChucToaDo) {
    this.ToChucToaDoService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucToaDoService.ComponentDeleteByParentID(this.ToChucToaDoSort, this.ToChucToaDoPaginator));
  }
  ToChucToaDoDeleteItemsByListID() {
    if (confirm(environment.DeleteConfirm)) {
      this.isShowLoading = true;
      this.ToChucToaDoService.baseParameter.SearchString = this.ToChucToaDoService.SearchString;
      this.ToChucToaDoService.DeleteItemsByListIDAsync().subscribe(
        res => {
          this.ToChucToaDoSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }

  ToChucDuAnGetToList() {
    this.isShowLoading = true;
    this.ToChucDuAnService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucDuAnService.GetByParentIDAndEmptyToListAsync().subscribe(
      res => {
        this.ToChucDuAnService.list = (res as ToChucDuAn[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucDuAnService.list001 = this.ToChucDuAnService.list;
        this.ToChucDuAnService.DataSource = new MatTableDataSource(this.ToChucDuAnService.list);
        this.ToChucDuAnService.DataSource.sort = this.ToChucDuAnSort;
        this.ToChucDuAnService.DataSource.paginator = this.ToChucDuAnPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucDuAnSearch() {
    if (this.searchString.length > 0) {
      this.ToChucDuAnService.DataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.ToChucDuAnGetToList();
    }
  }
  ToChucDuAnSave(element: ToChucDuAn) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucDuAnService.formData = element;
    this.NotificationService.warn(this.ToChucDuAnService.ComponentSaveByParentID(this.ToChucDuAnSort, this.ToChucDuAnPaginator));
  }
  ToChucDuAnDelete(element: ToChucDuAn) {
    this.ToChucDuAnService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucDuAnService.ComponentDeleteByParentID(this.ToChucDuAnSort, this.ToChucDuAnPaginator));
  }

  ToChucKhoThietBiGetToList() {
    this.isShowLoading = true;
    this.ToChucKhoThietBiService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucKhoThietBiService.GetByParentIDAndEmptyToListAsync().subscribe(
      res => {
        this.ToChucKhoThietBiService.list = (res as ToChucKhoThietBi[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucKhoThietBiService.list001 = this.ToChucKhoThietBiService.list;
        this.ToChucKhoThietBiService.DataSource = new MatTableDataSource(this.ToChucKhoThietBiService.list);
        this.ToChucKhoThietBiService.DataSource.sort = this.ToChucKhoThietBiSort;
        this.ToChucKhoThietBiService.DataSource.paginator = this.ToChucKhoThietBiPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucKhoThietBiSearch() {
    if (this.searchString.length > 0) {
      this.ToChucKhoThietBiService.DataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.ToChucKhoThietBiGetToList();
    }
  }
  // ToChucKhoThietBiSearch() {
  //   this.ToChucKhoThietBiService.baseParameter.ParentID = this.ToChucService.formData.ID;
  //   this.ToChucKhoThietBiService.SearchByParentID(this.ToChucKhoThietBiSort, this.ToChucKhoThietBiPaginator);
  // }
  ToChucKhoThietBiSave(element: ToChucKhoThietBi) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucKhoThietBiService.formData = element;
    this.NotificationService.warn(this.ToChucKhoThietBiService.ComponentSaveByParentID(this.ToChucKhoThietBiSort, this.ToChucKhoThietBiPaginator));
  }
  ToChucKhoThietBiDelete(element: ToChucKhoThietBi) {
    this.ToChucKhoThietBiService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucKhoThietBiService.ComponentDeleteByParentID(this.ToChucKhoThietBiSort, this.ToChucKhoThietBiPaginator));
  }

  ToChucSanPhamGetToList() {
    this.isShowLoading = true;
    this.ToChucSanPhamService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucSanPhamService.GetByParentIDAndEmptyToListAsync().subscribe(
      res => {
        this.ToChucSanPhamService.list = (res as ToChucSanPham[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucSanPhamService.list001 = this.ToChucSanPhamService.list;
        this.ToChucSanPhamService.DataSource = new MatTableDataSource(this.ToChucSanPhamService.list);
        this.ToChucSanPhamService.DataSource.sort = this.ToChucSanPhamSort;
        this.ToChucSanPhamService.DataSource.paginator = this.ToChucSanPhamPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucSanPhamSearch() {
    if (this.searchString.length > 0) {
      this.ToChucSanPhamService.DataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.ToChucSanPhamGetToList();
    }
  }
  // ToChucSanPhamSearch() {
  //   this.ToChucSanPhamService.baseParameter.ParentID = this.ToChucService.formData.ID;
  //   this.ToChucSanPhamService.SearchByParentID(this.ToChucSanPhamSort, this.ToChucSanPhamPaginator);
  // }
  ToChucSanPhamSave(element: ToChucSanPham) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucSanPhamService.formData = element;
    this.NotificationService.warn(this.ToChucSanPhamService.ComponentSaveByParentID(this.ToChucSanPhamSort, this.ToChucSanPhamPaginator));
  }
  ToChucSanPhamDelete(element: ToChucSanPham) {
    this.ToChucSanPhamService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucSanPhamService.ComponentDeleteByParentID(this.ToChucSanPhamSort, this.ToChucSanPhamPaginator));
  }

  ToChucTieuChuanSanXuatGetToList() {
    this.isShowLoading = true;
    this.ToChucTieuChuanSanXuatService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucTieuChuanSanXuatService.GetByParentIDAndEmptyToListAsync().subscribe(
      res => {
        this.ToChucTieuChuanSanXuatService.list = (res as ToChucTieuChuanSanXuat[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucTieuChuanSanXuatService.list001 = this.ToChucTieuChuanSanXuatService.list;
        this.ToChucTieuChuanSanXuatService.DataSource = new MatTableDataSource(this.ToChucTieuChuanSanXuatService.list);
        this.ToChucTieuChuanSanXuatService.DataSource.sort = this.ToChucTieuChuanSanXuatSort;
        this.ToChucTieuChuanSanXuatService.DataSource.paginator = this.ToChucTieuChuanSanXuatPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucTieuChuanSanXuatSearch() {
    if (this.searchString.length > 0) {
      this.ToChucTieuChuanSanXuatService.DataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.ToChucTieuChuanSanXuatGetToList();
    }
  }
  // ToChucTieuChuanSanXuatSearch() {
  //   this.ToChucTieuChuanSanXuatService.baseParameter.ParentID = this.ToChucService.formData.ID;
  //   this.ToChucTieuChuanSanXuatService.SearchByParentID(this.ToChucTieuChuanSanXuatSort, this.ToChucTieuChuanSanXuatPaginator);
  // }
  ToChucTieuChuanSanXuatSave(element: ToChucTieuChuanSanXuat) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucTieuChuanSanXuatService.formData = element;
    this.NotificationService.warn(this.ToChucTieuChuanSanXuatService.ComponentSaveByParentID(this.ToChucTieuChuanSanXuatSort, this.ToChucTieuChuanSanXuatPaginator));
  }
  ToChucTieuChuanSanXuatDelete(element: ToChucTieuChuanSanXuat) {
    this.ToChucTieuChuanSanXuatService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucTieuChuanSanXuatService.ComponentDeleteByParentID(this.ToChucTieuChuanSanXuatSort, this.ToChucTieuChuanSanXuatPaginator));
  }

  ToChucHopTacGetToList() {
    this.isShowLoading = true;
    this.ToChucHopTacService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucHopTacService.GetByParentIDAndEmptyToListAsync().subscribe(
      res => {
        this.ToChucHopTacService.list = (res as ToChucHopTac[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucHopTacService.list001 = this.ToChucHopTacService.list;
        this.ToChucHopTacService.DataSource = new MatTableDataSource(this.ToChucHopTacService.list);
        this.ToChucHopTacService.DataSource.sort = this.ToChucHopTacSort;
        this.ToChucHopTacService.DataSource.paginator = this.ToChucHopTacPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucHopTacSearch() {
    if (this.searchString.length > 0) {
      this.ToChucHopTacService.DataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.ToChucHopTacGetToList();
    }
  }
  // ToChucHopTacSearch() {
  //   this.ToChucHopTacService.baseParameter.ParentID = this.ToChucService.formData.ID;
  //   this.ToChucHopTacService.SearchByParentID(this.ToChucHopTacSort, this.ToChucHopTacPaginator);
  // }
  ToChucHopTacSave(element: ToChucHopTac) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucHopTacService.formData = element;
    this.NotificationService.warn(this.ToChucHopTacService.ComponentSaveByParentID(this.ToChucHopTacSort, this.ToChucHopTacPaginator));
  }
  ToChucHopTacDelete(element: ToChucHopTac) {
    this.ToChucHopTacService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucHopTacService.ComponentDeleteByParentID(this.ToChucHopTacSort, this.ToChucHopTacPaginator));
  }

  ToChucThanhVienGetToList() {
    this.isShowLoading = true;
    this.ToChucThanhVienService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucThanhVienService.GetByParentIDAndEmptyToListAsync().subscribe(
      res => {
        this.ToChucThanhVienService.list = (res as ToChucThanhVien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucThanhVienService.list001 = this.ToChucThanhVienService.list;
        this.ToChucThanhVienService.DataSource = new MatTableDataSource(this.ToChucThanhVienService.list);
        this.ToChucThanhVienService.DataSource.sort = this.ToChucThanhVienSort;
        this.ToChucThanhVienService.DataSource.paginator = this.ToChucThanhVienPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucThanhVienSearch() {
    if (this.searchString.length > 0) {
      this.ToChucThanhVienService.DataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.ToChucThanhVienGetToList();
    }
  }
  // ToChucThanhVienSearch() {
  //   this.ToChucThanhVienService.baseParameter.ParentID = this.ToChucService.formData.ID;    
  //   this.ToChucThanhVienService.SearchByParentID(this.ToChucThanhVienSort, this.ToChucThanhVienPaginator);
  // }
  ToChucThanhVienSave(element: ToChucThanhVien) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucThanhVienService.formData = element;
    this.NotificationService.warn(this.ToChucThanhVienService.ComponentSaveByParentID(this.ToChucThanhVienSort, this.ToChucThanhVienPaginator));
  }
  ToChucThanhVienDelete(element: ToChucThanhVien) {
    this.ToChucThanhVienService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucThanhVienService.ComponentDeleteByParentID(this.ToChucThanhVienSort, this.ToChucThanhVienPaginator));
  }

  ToChucSave() {
    this.isShowLoading = true;
    this.ToChucService.formData.ParentID = environment.HopTacXaID;
    this.ToChucService.SaveAsync().subscribe(
      res => {
        this.ToChucService.formData = res as ToChuc;
        this.GetAll();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucNew() {
    this.isShowLoading = true;
    this.ToChucService.formData.ID = 0;
    this.ToChucService.formData.ParentID = environment.HopTacXaID;
    this.ToChucService.SaveAsync().subscribe(
      res => {
        this.ToChucService.formData = res as ToChuc;
        this.GetAll();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }

  Close() {
    this.dialogRef.close();
  }

  ToChucGetToList() {
    this.ToChucService.ComponentGetAllToListAsync();
  }

  DanhMucDuAnGetToList() {
    this.DanhMucDuAnService.ComponentGetAllToListAsync();
  }

  DanhMucToChucGetToList() {
    this.DanhMucToChucService.ComponentGetAllToListAsync();
  }

  DanhMucThietBiGetToList() {
    this.DanhMucThietBiService.ComponentGetAllToListAsync();
  }

  DanhMucTieuChuanSanXuatGetToList() {
    this.DanhMucTieuChuanSanXuatService.ComponentGetAllToListAsync();
  }

  DanhMucSanPhamGetToList() {
    this.DanhMucSanPhamService.ComponentGetAllToListAsync();
  }

  DanhMucLienKetGetToList() {
    this.DanhMucLienKetService.ComponentGetAllToListAsync();
  }

  DanhMucChucDanhGetToList() {
    this.DanhMucChucDanhService.ComponentGetAllToListAsync();
  }

  DanhMucChungChiGetToList() {
    this.DanhMucChungChiService.ComponentGetAllToListAsync();
  }


  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        this.DanhMucQuanHuyenGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.ToChucService.formData.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.ToChucService.formData.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }

  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.map?.remove();
  }

  MapInitialization(longitude, latitude) {

    let zoom = 12;

    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }
    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
      center: [longitude, latitude],
      zoom: zoom,       
    });

    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );
    this.map.addControl(
      new maplibregl.FullscreenControl({       
      })
    );
    this.map.on('load', () => {

      this.map.addSource("HoangSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa",
        "source": "HoangSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa",
        "source": "TruongSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }

  MapLoad() {

    if (this.ToChucService.formData) {
      this.ToChucService.baseParameter.ID = this.ToChucService.formData.ID;
      this.ToChucService.GetSQLByToChucToaDoByIDToListAsync().subscribe(res => {
        this.ToChucService.list1003 = res as ToChuc[];
        if (this.ToChucService.list1003) {
          if (this.ToChucService.list1003.length > 0) {
            let listToChucToaDoActive = [];
            let listToChucToaDoActiveNot = [];

            for (let i = 0; i < this.ToChucService.list1003.length; i++) {
              if (this.ToChucService.list1003[i].Active == true) {
                listToChucToaDoActive.push(this.ToChucService.list1003[i]);
              }
              if (this.ToChucService.list1003[i].Active == false) {
                listToChucToaDoActiveNot.push(this.ToChucService.list1003[i]);
              }
            }
            let toChuc = this.ToChucService.list1003[0];
            let latitude = environment.Latitude;
            let longitude = environment.Longitude;
            if (listToChucToaDoActive) {
              if (listToChucToaDoActive.length > 0) {
                toChuc = listToChucToaDoActive[0];
              }
            }
            latitude = Number(toChuc.ViDo);
            longitude = Number(toChuc.KinhDo);
            this.MapInitialization(longitude, latitude);

            if (latitude <= 90) {

              let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
              popupContent = popupContent + "<a class='link-primary form-label' href='" + environment.DomainDestination + "HopTacXaInfo/" + toChuc.ID + "'><h1>" + toChuc.Name + "</h1></a>";
              popupContent = popupContent + '<div><b>' + toChuc.DanhMucXaPhuongName + ', ' + toChuc.DanhMucQuanHuyenName + ', ' + toChuc.DanhMucTinhThanhName + '</b></div>';
              popupContent = popupContent + "</div>";

              let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                .setMaxWidth("600px");

              var el = document.createElement('div');
              el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/HTX4_30.png)";
              el.style.width = '30px';
              el.style.height = '30px';

              let marker = new maplibregl.Marker({ element: el })
                .setLngLat([longitude, latitude])
                .setPopup(popup)
                .addTo(this.map);
            }
            
            let listCode = [];
            let code = '';
            for (let i = 0; i < listToChucToaDoActiveNot.length; i++) {
              if (code != listToChucToaDoActiveNot[i].Code) {
                code = listToChucToaDoActiveNot[i].Code;
                listCode.push(code);
              }
            }
            for (let i = 0; i < listCode.length; i++) {
              code = listCode[i];
              let listToChucToaDoPolygon = [];
              let listPolygon = [];
              let listToChucToaDo = listToChucToaDoActiveNot.filter((item) => item.Code == code);
              for (let j = 0; j < listToChucToaDo.length; j++) {
                let latitudeSub1 = Number(listToChucToaDo[j].ViDo);
                let longitudeSub1 = Number(listToChucToaDo[j].KinhDo);
                listToChucToaDoPolygon.push([longitudeSub1, latitudeSub1]);
              }
              if (listToChucToaDoPolygon.length > 0) {
                listPolygon.push(listToChucToaDoPolygon);
                let layerID = 'Layer_' + code;
                let sourceID = 'Source_' + code;
                this.map.on('load', () => {
                  this.map.addSource(sourceID, {
                    'type': 'geojson',
                    'data': {
                      'type': 'Feature',
                      'properties': {
                        "name": "",
                        "address": "",
                      },
                      'geometry': {
                        'type': 'Polygon',
                        'coordinates': listPolygon,
                      }
                    }
                  });
                  let color = this.DownloadService.GetRandomColor(listToChucToaDoPolygon.length);
                  this.map.addLayer({
                    'id': layerID,
                    'type': 'fill',
                    'source': sourceID,
                    'paint': {
                      'fill-color': color,
                      'fill-opacity': 0.5,
                      'fill-outline-color': color,
                    }
                  });
                });
              }
            }
          }
        }
      });
    }
  }
}
