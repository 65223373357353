<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-shield-check fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Quảng cáo</h2>
            <h6 class="m-0 text-dark fw-light">
                Điện thoại
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThongBaoDienThoai" data-bs-toggle="tab"
                                    href="#ThongBaoDienThoai" role="tab" aria-controls="ThongBaoDienThoai"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Chức năng</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThongBaoDienThoai" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ThongBaoDienThoaiService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ThongBaoDienThoaiSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="ThongBaoDienThoaiSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Chức năng
                                            <span
                                                *ngIf="ThongBaoDienThoaiService.list">({{ThongBaoDienThoaiService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ThongBaoDienThoaiSort="matSort"
                                                    [dataSource]="ThongBaoDienThoaiService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ThongBaoDienThoaiPaginator.pageSize *
                                                            ThongBaoDienThoaiPaginator.pageIndex + i + 1}} [{{element.ID}}]
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <!--
                                                            <input class="form-control" type="text"
                                                                placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                            -->

                                                            <textarea class="form-control" placeholder="Tiêu đề" name="Name{{element.ID}}" [(ngModel)]="element.Name"></textarea>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TypeName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>URL</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="URL"
                                                                name="TypeName{{element.ID}}" [(ngModel)]="element.TypeName">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="BatDau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bắt đầu</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input name="BatDau{{element.ID}}"
                                                                [ngModel]="element.BatDau | date:'yyyy-MM-dd'"
                                                                (ngModelChange)="DateThongBaoDienThoaiBatDau(element, $event)"
                                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                                class="form-control" autocomplete="off">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetThuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kết thúc</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input name="KetThuc{{element.ID}}"
                                                                [ngModel]="element.KetThuc | date:'yyyy-MM-dd'"
                                                                (ngModelChange)="DateThongBaoDienThoaiKetThuc(element, $event)"
                                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                                class="form-control" autocomplete="off">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsKhongKetThuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Không<br/>kết thúc
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsKhongKetThuc{{element.ID}}"
                                                                    [(ngModel)]="element.IsKhongKetThuc">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsPopUp">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Popup
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsPopUp{{element.ID}}"
                                                                    [(ngModel)]="element.IsPopUp">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="SortOrder{{element.ID}}"
                                                                [(ngModel)]="element.SortOrder"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hình ảnh
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a target="_blank" href="{{element.FileName}}" *ngIf="element.FileName"
                                                                            title="{{element.FileName}}">
                                                                            <img class="responsive-img" width="100%" title="{{element.FileName}}"
                                                                                alt="{{element.FileName}}" [src]="element.FileName"></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="FileName">
                                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Upload
                                                        </mat-header-cell>
                                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                            <input type="file"
                                                                (change)="changeFileName(element, $event.target.files)" style="width: 90px;">
                                                        </mat-cell>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ThongBaoDienThoaiSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ThongBaoDienThoaiDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThongBaoDienThoaiService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThongBaoDienThoaiService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ThongBaoDienThoaiPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>