<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bank2 fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Hướng dẫn sử dụng
            </h2>
            <h6 class="m-0 text-dark fw-light">
                Giới thiệu
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThongBaoDetail" data-bs-toggle="tab"
                                    href="#ThongBaoDetail" role="tab" aria-controls="ThongBaoDetail"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Hướng dẫn sử dụng</span>
                                </a>
                            </li>

                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThongBaoDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                    
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b>1. FBS APP ~ HƯỚNG DẪN ĐĂNG NHẬP (BẢN KỸ THUẬT)</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <iframe width="100%" height="600"
                                                src="https://www.youtube.com/embed/it7fPEHim7Q?si=yK2bG1DGlkGxtLt7"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowfullscreen></iframe>
                                        </div>
                                        <hr/>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b>2. FBS APP ~ GIỚI THIỆU TỔNG QUAN VỀ ỨNG DỤNG</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <iframe width="100%" height="600"
                                                src="https://www.youtube.com/embed/Klawb0opy04?si=g2efTdXcKppyNbk8"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowfullscreen></iframe>
                                        </div>
                                        <hr/>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b>3. FBS APP HƯỚNG DẪN TẠO TRANG TRẠI VÀ CÔNG VIỆC</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <iframe width="100%" height="600"
                                                src="https://www.youtube.com/embed/aKdtPzyPJb0?si=2_XKmYUHPYiEnIAM"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowfullscreen></iframe>
                                        </div>
                                        <hr/>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b>4. FBS APP ~ HƯỚNG DẪN TẠO KẾ HOẠCH VÀ QUẢN LÝ DÒNG TIỀN SẢN XUẤT TRONG MÙA VỤ</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <iframe width="100%" height="600"
                                                src="https://www.youtube.com/embed/9lMlNoMFd7I?si=dI3Oie4aVfuUZqlN"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowfullscreen></iframe>
                                        </div>
                                        <hr/>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b>5. FBS APP ~ HƯỚNG DẪN QUẢN LÝ CHI TIÊU TRONG GIA ĐÌNH</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <iframe width="100%" height="600"
                                                src="https://www.youtube.com/embed/lpif2UsowLg?si=QcvQx3t7kXuKGSoh"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowfullscreen></iframe>
                                        </div>
                                        <hr/>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b>6. FBS APP ~ HƯỚNG DẪN QUẢN LÝ CHI TIÊU TRONG GIA ĐÌNH</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <iframe width="100%" height="600"
                                                src="https://www.youtube.com/embed/KDlrYUK16AI?si=jl5s1H6mbQhJddkV"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowfullscreen></iframe>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>