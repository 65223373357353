import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thanh-vien-thiet-bi',
  templateUrl: './thanh-vien-thiet-bi.component.html',
  styleUrls: ['./thanh-vien-thiet-bi.component.css']
})
export class ThanhVienThietBiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
