import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucTaiLieu } from 'src/app/shared/DanhMucTaiLieu.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucTaiLieuService extends BaseService{

    list: DanhMucTaiLieu[] | undefined;
    list001: DanhMucTaiLieu[] | undefined;
    formData!: DanhMucTaiLieu;

    
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DanhMucTaiLieu";
    }
}

