import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucChucDanh } from 'src/app/shared/DanhMucChucDanh.model';
import { DanhMucChucDanhService } from 'src/app/shared/DanhMucChucDanh.service';
import { DanhMucChungChi } from 'src/app/shared/DanhMucChungChi.model';
import { DanhMucChungChiService } from 'src/app/shared/DanhMucChungChi.service';
import { DanhMucLienKet } from 'src/app/shared/DanhMucLienKet.model';
import { DanhMucLienKetService } from 'src/app/shared/DanhMucLienKet.service';
import { DanhMucSanPham } from 'src/app/shared/DanhMucSanPham.model';
import { DanhMucSanPhamService } from 'src/app/shared/DanhMucSanPham.service';
import { DanhMucTieuChuanSanXuat } from 'src/app/shared/DanhMucTieuChuanSanXuat.model';
import { DanhMucTieuChuanSanXuatService } from 'src/app/shared/DanhMucTieuChuanSanXuat.service';
import { DanhMucThietBi } from 'src/app/shared/DanhMucThietBi.model';
import { DanhMucThietBiService } from 'src/app/shared/DanhMucThietBi.service';
import { DanhMucDuAn } from 'src/app/shared/DanhMucDuAn.model';
import { DanhMucDuAnService } from 'src/app/shared/DanhMucDuAn.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { ToChucThanhVien } from 'src/app/shared/ToChucThanhVien.model';
import { ToChucThanhVienService } from 'src/app/shared/ToChucThanhVien.service';
import { ToChucHopTac } from 'src/app/shared/ToChucHopTac.model';
import { ToChucHopTacService } from 'src/app/shared/ToChucHopTac.service';
import { ToChucTieuChuanSanXuat } from 'src/app/shared/ToChucTieuChuanSanXuat.model';
import { ToChucTieuChuanSanXuatService } from 'src/app/shared/ToChucTieuChuanSanXuat.service';
import { ToChucSanPham } from 'src/app/shared/ToChucSanPham.model';
import { ToChucSanPhamService } from 'src/app/shared/ToChucSanPham.service';
import { ToChucKhoThietBi } from 'src/app/shared/ToChucKhoThietBi.model';
import { ToChucKhoThietBiService } from 'src/app/shared/ToChucKhoThietBi.service';
import { ToChucDuAn } from 'src/app/shared/ToChucDuAn.model';
import { ToChucDuAnService } from 'src/app/shared/ToChucDuAn.service';
import { ToChucToaDo } from 'src/app/shared/ToChucToaDo.model';
import { ToChucToaDoService } from 'src/app/shared/ToChucToaDo.service';
import { ToChucQuyTrinhSanXuat } from 'src/app/shared/ToChucQuyTrinhSanXuat.model';
import { ToChucQuyTrinhSanXuatService } from 'src/app/shared/ToChucQuyTrinhSanXuat.service';

import { ToChucDetailComponent } from '../to-chuc-detail/to-chuc-detail.component';
import { ToChucQuyTrinhSanXuatDetailComponent } from '../to-chuc-quy-trinh-san-xuat-detail/to-chuc-quy-trinh-san-xuat-detail.component';

@Component({
  selector: 'app-hop-tac-xa',
  templateUrl: './hop-tac-xa.component.html',
  styleUrls: ['./hop-tac-xa.component.css']
})
export class HopTacXaComponent implements OnInit {

  ToChucDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucSort') ToChucSort: MatSort;
  @ViewChild('ToChucPaginator') ToChucPaginator: MatPaginator;

  ToChucThanhVienDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucThanhVienSort') ToChucThanhVienSort: MatSort;
  @ViewChild('ToChucThanhVienPaginator') ToChucThanhVienPaginator: MatPaginator;

  ToChucHopTacDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucHopTacSort') ToChucHopTacSort: MatSort;
  @ViewChild('ToChucHopTacPaginator') ToChucHopTacPaginator: MatPaginator;

  ToChucTieuChuanSanXuatDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucTieuChuanSanXuatSort') ToChucTieuChuanSanXuatSort: MatSort;
  @ViewChild('ToChucTieuChuanSanXuatPaginator') ToChucTieuChuanSanXuatPaginator: MatPaginator;

  ToChucSanPhamDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucSanPhamSort') ToChucSanPhamSort: MatSort;
  @ViewChild('ToChucSanPhamPaginator') ToChucSanPhamPaginator: MatPaginator;

  ToChucKhoThietBiDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucKhoThietBiSort') ToChucKhoThietBiSort: MatSort;
  @ViewChild('ToChucKhoThietBiPaginator') ToChucKhoThietBiPaginator: MatPaginator;

  ToChucDuAnDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucDuAnSort') ToChucDuAnSort: MatSort;
  @ViewChild('ToChucDuAnPaginator') ToChucDuAnPaginator: MatPaginator;

  ToChucToaDoDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucToaDoSort') ToChucToaDoSort: MatSort;
  @ViewChild('ToChucToaDoPaginator') ToChucToaDoPaginator: MatPaginator;

  ToChucErrorDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucErrorSort') ToChucErrorSort: MatSort;
  @ViewChild('ToChucErrorPaginator') ToChucErrorPaginator: MatPaginator;


  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  domainName = environment.DomainDestination;
  DanhMucTinhThanhID: number = environment.InitializationNumber;
  DanhMucQuanHuyenID: number = environment.InitializationNumber;
  DanhMucXaPhuongID: number = environment.InitializationNumber;
  ToChucID: number = environment.InitializationNumber;



  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,


    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucChucDanhService: DanhMucChucDanhService,
    public DanhMucChungChiService: DanhMucChungChiService,
    public DanhMucLienKetService: DanhMucLienKetService,
    public DanhMucSanPhamService: DanhMucSanPhamService,
    public DanhMucTieuChuanSanXuatService: DanhMucTieuChuanSanXuatService,
    public DanhMucThietBiService: DanhMucThietBiService,
    public DanhMucDuAnService: DanhMucDuAnService,

    public ToChucService: ToChucService,
    public ToChucThanhVienService: ToChucThanhVienService,
    public ToChucHopTacService: ToChucHopTacService,
    public ToChucTieuChuanSanXuatService: ToChucTieuChuanSanXuatService,
    public ToChucSanPhamService: ToChucSanPhamService,
    public ToChucKhoThietBiService: ToChucKhoThietBiService,
    public ToChucDuAnService: ToChucDuAnService,
    public ToChucToaDoService: ToChucToaDoService,
    public ToChucQuyTrinhSanXuatService: ToChucQuyTrinhSanXuatService,
  ) { }

  ngOnInit(): void {
    this.DanhMucDuAnGetToList();
    this.DanhMucThietBiGetToList();
    this.DanhMucTieuChuanSanXuatGetToList();
    this.DanhMucSanPhamGetToList();
    this.DanhMucLienKetGetToList();
    this.DanhMucChucDanhGetToList();
    this.DanhMucChungChiGetToList();
    this.DanhMucTinhThanhGetToList();
    this.ToChucSearchAll();
  }

  ToChucSearchAll() {
    this.ToChucGetAllToList();
    this.ToChucThanhVienSearch();
    this.ToChucHopTacSearch();
    this.ToChucTieuChuanSanXuatSearch();
    this.ToChucSanPhamSearch();
    this.ToChucKhoThietBiSearch();
    this.ToChucDuAnSearch();
    this.ToChucToaDoSearch();
  }

  ToChucExportHopTacXaByIDToHTMLAsync(element: ToChuc) {
    this.isShowLoading = true;
    this.DownloadService.baseParameter.ID = element.ID;
    this.DownloadService.ExportHopTacXaByIDToHTMLAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucErrorSearch() {
    if (this.ToChucService.SearchString.length > 0) {
      this.ToChucErrorDataSource.filter = this.ToChucService.SearchString.toLowerCase();
    }
    else {
      this.ToChucErrorGetToList();
    }
  }

  ToChucErrorGetToList() {
    this.isShowLoading = true;
    this.ToChucService.GetByErrorToListAsync().subscribe(
      res => {
        this.ToChucService.list0001 = (res as ToChuc[]);
        this.ToChucErrorDataSource = new MatTableDataSource(this.ToChucService.list0001);
        this.ToChucErrorDataSource.sort = this.ToChucErrorSort;
        this.ToChucErrorDataSource.paginator = this.ToChucErrorPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucToaDoSearch() {
    this.ToChucToaDoService.baseParameter.ParentID = this.ToChucID;
    this.ToChucToaDoService.SearchByParentID(this.ToChucToaDoSort, this.ToChucToaDoPaginator);
  }
  ToChucToaDoSave(element: ToChucToaDo) {
    element.ParentID = this.ToChucID;
    this.ToChucToaDoService.formData = element;
    this.NotificationService.warn(this.ToChucToaDoService.ComponentSaveByParentID(this.ToChucToaDoSort, this.ToChucToaDoPaginator));
  }
  ToChucToaDoDelete(element: ToChucToaDo) {
    this.ToChucToaDoService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucToaDoService.ComponentDeleteByParentID(this.ToChucToaDoSort, this.ToChucToaDoPaginator));
  }
  ToChucToaDoDeleteItemsByListID() {
    if (confirm(environment.DeleteConfirm)) {
      this.isShowLoading = true;
      this.ToChucToaDoService.baseParameter.SearchString = this.ToChucToaDoService.SearchString;
      this.ToChucToaDoService.DeleteItemsByListIDAsync().subscribe(
        res => {
          this.ToChucToaDoSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }

  ToChucDuAnSearch() {
    this.ToChucDuAnService.baseParameter.ParentID = this.ToChucID;
    this.ToChucDuAnService.SearchByParentID(this.ToChucDuAnSort, this.ToChucDuAnPaginator);
  }
  ToChucDuAnSave(element: ToChucDuAn) {
    element.ParentID = this.ToChucID;
    this.ToChucDuAnService.formData = element;
    this.NotificationService.warn(this.ToChucDuAnService.ComponentSaveByParentID(this.ToChucDuAnSort, this.ToChucDuAnPaginator));
  }
  ToChucDuAnDelete(element: ToChucDuAn) {
    this.ToChucDuAnService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucDuAnService.ComponentDeleteByParentID(this.ToChucDuAnSort, this.ToChucDuAnPaginator));
  }

  ToChucKhoThietBiSearch() {
    this.ToChucKhoThietBiService.baseParameter.ParentID = this.ToChucID;
    this.ToChucKhoThietBiService.SearchByParentID(this.ToChucKhoThietBiSort, this.ToChucKhoThietBiPaginator);
  }
  ToChucKhoThietBiSave(element: ToChucKhoThietBi) {
    element.ParentID = this.ToChucID;
    this.ToChucKhoThietBiService.formData = element;
    this.NotificationService.warn(this.ToChucKhoThietBiService.ComponentSaveByParentID(this.ToChucKhoThietBiSort, this.ToChucKhoThietBiPaginator));
  }
  ToChucKhoThietBiDelete(element: ToChucKhoThietBi) {
    this.ToChucKhoThietBiService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucKhoThietBiService.ComponentDeleteByParentID(this.ToChucKhoThietBiSort, this.ToChucKhoThietBiPaginator));
  }

  ToChucSanPhamSearch() {
    this.ToChucSanPhamService.baseParameter.ParentID = this.ToChucID;
    this.ToChucSanPhamService.SearchByParentID(this.ToChucSanPhamSort, this.ToChucSanPhamPaginator);
  }
  ToChucSanPhamSave(element: ToChucSanPham) {
    element.ParentID = this.ToChucID;
    this.ToChucSanPhamService.formData = element;
    this.NotificationService.warn(this.ToChucSanPhamService.ComponentSaveByParentID(this.ToChucSanPhamSort, this.ToChucSanPhamPaginator));
  }
  ToChucSanPhamDelete(element: ToChucSanPham) {
    this.ToChucSanPhamService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucSanPhamService.ComponentDeleteByParentID(this.ToChucSanPhamSort, this.ToChucSanPhamPaginator));
  }

  ToChucTieuChuanSanXuatSearch() {
    this.ToChucTieuChuanSanXuatService.baseParameter.ParentID = this.ToChucID;
    this.ToChucTieuChuanSanXuatService.SearchByParentID(this.ToChucTieuChuanSanXuatSort, this.ToChucTieuChuanSanXuatPaginator);
  }
  ToChucTieuChuanSanXuatSave(element: ToChucTieuChuanSanXuat) {
    element.ParentID = this.ToChucID;
    this.ToChucTieuChuanSanXuatService.formData = element;
    this.NotificationService.warn(this.ToChucTieuChuanSanXuatService.ComponentSaveByParentID(this.ToChucTieuChuanSanXuatSort, this.ToChucTieuChuanSanXuatPaginator));
  }
  ToChucTieuChuanSanXuatDelete(element: ToChucTieuChuanSanXuat) {
    this.ToChucTieuChuanSanXuatService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucTieuChuanSanXuatService.ComponentDeleteByParentID(this.ToChucTieuChuanSanXuatSort, this.ToChucTieuChuanSanXuatPaginator));
  }

  ToChucHopTacSearch() {
    this.ToChucHopTacService.baseParameter.ParentID = this.ToChucID;
    this.ToChucHopTacService.SearchByParentID(this.ToChucHopTacSort, this.ToChucHopTacPaginator);
  }
  ToChucHopTacSave(element: ToChucHopTac) {
    element.ParentID = this.ToChucID;
    this.ToChucHopTacService.formData = element;
    this.NotificationService.warn(this.ToChucHopTacService.ComponentSaveByParentID(this.ToChucHopTacSort, this.ToChucHopTacPaginator));
  }
  ToChucHopTacDelete(element: ToChucHopTac) {
    this.ToChucHopTacService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucHopTacService.ComponentDeleteByParentID(this.ToChucHopTacSort, this.ToChucHopTacPaginator));
  }

  ToChucThanhVienSearch() {
    this.ToChucThanhVienService.baseParameter.ParentID = this.ToChucID;
    this.ToChucThanhVienService.SearchByParentID(this.ToChucThanhVienSort, this.ToChucThanhVienPaginator);
  }
  ToChucThanhVienSave(element: ToChucThanhVien) {
    element.ParentID = this.ToChucID;
    this.ToChucThanhVienService.formData = element;
    this.NotificationService.warn(this.ToChucThanhVienService.ComponentSaveByParentID(this.ToChucThanhVienSort, this.ToChucThanhVienPaginator));
  }
  ToChucThanhVienDelete(element: ToChucThanhVien) {
    this.ToChucThanhVienService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucThanhVienService.ComponentDeleteByParentID(this.ToChucThanhVienSort, this.ToChucThanhVienPaginator));
  }

  DanhMucDuAnGetToList() {
    this.DanhMucDuAnService.ComponentGetAllToListAsync();
  }

  DanhMucThietBiGetToList() {
    this.DanhMucThietBiService.ComponentGetAllToListAsync();
  }

  DanhMucTieuChuanSanXuatGetToList() {
    this.DanhMucTieuChuanSanXuatService.ComponentGetAllToListAsync();
  }

  DanhMucSanPhamGetToList() {
    this.DanhMucSanPhamService.ComponentGetAllToListAsync();
  }

  DanhMucLienKetGetToList() {
    this.DanhMucLienKetService.ComponentGetAllToListAsync();
  }

  DanhMucChucDanhGetToList() {
    this.DanhMucChucDanhService.ComponentGetAllToListAsync();
  }

  DanhMucChungChiGetToList() {
    this.DanhMucChungChiService.ComponentGetAllToListAsync();
  }

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.baseParameter.Active = true;
    this.DanhMucTinhThanhService.GetSQLByThanhVienIDAndActiveToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length) {
            this.DanhMucTinhThanhID = this.DanhMucTinhThanhService.list[0].ID;
          }
        }
        this.DanhMucQuanHuyenGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetSQLByThanhVienIDAndActiveAndParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetSQLByThanhVienIDAndActiveAndParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
    this.ToChucSearch();
  }

  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }

  ToChucGetToList() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.ParentID = environment.HopTacXaID;
    this.ToChucService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ToChucService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ToChucService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ToChucService.baseParameter.SearchString = this.ToChucService.SearchString;
    this.ToChucService.GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ToChucService.list000 = (res as ToChuc[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        if (this.ToChucService.list000) {
          if (this.ToChucService.list000.length > 0) {
            this.ToChucID = this.ToChucService.list000[0].ID;
          }
        }
        this.ToChucDataSource = new MatTableDataSource(this.ToChucService.list000);
        this.ToChucDataSource.sort = this.ToChucSort;
        this.ToChucDataSource.paginator = this.ToChucPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucGetAllToList() {
    this.ToChucService.baseParameter.ParentID = environment.HopTacXaID;
    this.ToChucService.ComponentGetByParentIDToListAsync();
  }

  ToChucSearch() {
    this.ToChucGetToList();
    this.ToChucGetAllToList();
  }

  ToChucFilter(searchString: string) {
    searchString = searchString.trim();
    this.ToChucService.Filter(searchString);
  }

  ToChucAdd(ID: any) {
    this.ToChucService.baseParameter.ID = ID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.formData = res as ToChuc;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ToChucDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
      },
      err => {
      }
    );
  }

  ToChucQuyTrinhSanXuatAdd(ID: any) {
    this.ToChucQuyTrinhSanXuatService.baseParameter.ParentID = ID;
    this.ToChucQuyTrinhSanXuatService.baseParameter.DanhMucDuAnID = 1;
    this.ToChucQuyTrinhSanXuatService.baseParameter.DanhMucSanPhamID = 890;
    this.ToChucQuyTrinhSanXuatService.GetByParentID_DanhMucDuAnID_DanhMucSanPhamIDAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatService.formData = res as ToChucQuyTrinhSanXuat;
        console.log(this.ToChucQuyTrinhSanXuatService.formData);
        if (this.ToChucQuyTrinhSanXuatService.formData) {
          if (this.ToChucQuyTrinhSanXuatService.formData.ID > 0) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.width = environment.DialogConfigWidth;
            dialogConfig.data = { ID: this.ToChucQuyTrinhSanXuatService.formData.ID };
            const dialog = this.dialog.open(ToChucQuyTrinhSanXuatDetailComponent, dialogConfig);
            dialog.afterClosed().subscribe(() => {
            });
          }
          else {
            this.NotificationService.warn(environment.HTX0001);
          }
        }
        else {
          this.NotificationService.warn(environment.HTX0001);
        }
      },
      err => {
      }
    );
  }
}
