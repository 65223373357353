import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { DanhMucDuAn } from 'src/app/shared/DanhMucDuAn.model';
import { DanhMucDuAnService } from 'src/app/shared/DanhMucDuAn.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { ThanhVienThongBao } from 'src/app/shared/ThanhVienThongBao.model';
import { ThanhVienThongBaoService } from 'src/app/shared/ThanhVienThongBao.service';

@Component({
  selector: 'app-thanh-vien-thong-bao',
  templateUrl: './thanh-vien-thong-bao.component.html',
  styleUrls: ['./thanh-vien-thong-bao.component.css']
})
export class ThanhVienThongBaoComponent implements OnInit {

  @ViewChild('ThanhVienThongBaoSort') ThanhVienThongBaoSort: MatSort;
  @ViewChild('ThanhVienThongBaoPaginator') ThanhVienThongBaoPaginator: MatPaginator;

  isShowLoading: boolean = false;

  constructor(
    public NotificationService: NotificationService,

    public DanhMucDuAnService: DanhMucDuAnService,

    public ThanhVienService: ThanhVienService,
    public ThanhVienThongBaoService: ThanhVienThongBaoService,
  ) { }

  ngOnInit(): void {
    this.DanhMucDuAnSearch();
    this.ThanhVienSearch();
  }

  DanhMucDuAnSearch() {
    this.DanhMucDuAnService.ComponentGetAllToListAsync();
  }

  ThanhVienSearch() {
    this.ThanhVienService.ComponentGetAllToListAsync();
  }  
  ThanhVienFilter(searchString: string) {
    this.ThanhVienService.FilterFull(searchString);
  }

  ThanhVienThongBaoSearch() {
    this.ThanhVienThongBaoService.ComponentGetAllAndEmptyToListAsync(this.ThanhVienThongBaoSort, this.ThanhVienThongBaoPaginator);
  }
  ThanhVienThongBaoSave(element: ThanhVienThongBao) {
    this.ThanhVienThongBaoService.formData = element;
    this.NotificationService.warn(this.ThanhVienThongBaoService.ComponentSaveAll(this.ThanhVienThongBaoSort, this.ThanhVienThongBaoPaginator));
  }
  ThanhVienThongBaoDelete(element: ThanhVienThongBao) {
    this.ThanhVienThongBaoService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ThanhVienThongBaoService.ComponentDeleteAll(this.ThanhVienThongBaoSort, this.ThanhVienThongBaoPaginator));
  }


}
