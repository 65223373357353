<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-shield-check fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Tài liệu</h2>
            <h6 class="m-0 text-dark fw-light">
                Công cụ điều hành
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">       
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-BaiViet" data-bs-toggle="tab" href="#BaiViet" role="tab"
                                    aria-controls="BaiViet" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Bài viết</span>
                                </a>
                            </li>                      
                            <li class="nav-item" role="presentation">
                                <a class="nav-link " id="tab-TaiLieu" data-bs-toggle="tab" href="#TaiLieu" role="tab"
                                    aria-controls="TaiLieu" aria-selected="true">
                                    <span class="badge bg-dark" style="font-size: 16px;">Tài liệu</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">                           
                            
                            <div class="tab-pane fade show active" id="BaiViet" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">Loại bài viết</label>
                                        <select class="form-select" name="DanhMucBaiVietID"
                                            [(ngModel)]="DanhMucBaiVietID">                                          
                                            <option *ngFor="let item of DanhMucBaiVietService.list;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">&nbsp;</label>
                                        <input [(ngModel)]="BaiVietService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="BaiVietSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">&nbsp;</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="BaiVietSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-success" title="Thêm mới" (click)="BaiVietAdd(0)"><i
                                                    class="bi bi-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Bài viết
                                            <span *ngIf="BaiVietService.list">({{BaiVietService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #BaiVietSort="matSort"
                                                    [dataSource]="BaiVietService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{BaiVietPaginator.pageSize *
                                                            BaiVietPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="FileName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                          Hình đại diện
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                          <a style="cursor: pointer;" title="{{element.Name}}" class="link-primary"
                                                            (click)="BaiVietAdd(element.ID)"><img width="120" height="80"
                                                              src="{{element.FileName}}" /></a>
                                                        </td>
                                                      </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày đăng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.NgayGhiNhan | date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Name}}"
                                                                class="link-primary"
                                                                (click)="BaiVietAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.SortOrder}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-danger" (click)="BaiVietDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="BaiVietService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: BaiVietService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #BaiVietPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div class="tab-pane fade" id="TaiLieu" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">Loại tài liệu</label>
                                        <select class="form-select" name="DanhMucTaiLieuID"
                                            [(ngModel)]="DanhMucTaiLieuID">                                          
                                            <option *ngFor="let item of DanhMucTaiLieuService.list;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <label class="form-label">&nbsp;</label>
                                        <input [(ngModel)]="TaiLieuService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="TaiLieuSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">&nbsp;</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="TaiLieuSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                    
                                </div>
                                <div class="card mb-4 card-dark">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Tài liệu
                                            <span *ngIf="TaiLieuService.list">({{TaiLieuService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #TaiLieuSort="matSort"
                                                    [dataSource]="TaiLieuService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{TaiLieuPaginator.pageSize *
                                                            TaiLieuPaginator.pageIndex + i + 1}} [{{element.ID}}]
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Display">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hiển thị
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tên sách" name="Display{{element.ID}}"
                                                                [(ngModel)]="element.Display">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Mục lục" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đường dẫn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Video" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="SortOrder{{element.ID}}"
                                                                [(ngModel)]="element.SortOrder"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsTruyenThong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Truyền thông
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsTruyenThong{{element.ID}}"
                                                                    [(ngModel)]="element.IsTruyenThong">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="FileName">
                                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Upload
                                                        </mat-header-cell>
                                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                            <input type="file"
                                                                (change)="changeFileName(element, $event.target.files)">
                                                        </mat-cell>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info" (click)="TaiLieuSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger" (click)="TaiLieuDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-success" href="{{element.FileName}}"
                                                                target="_blank" *ngIf="element.ID>0"><i
                                                                    class="bi bi-cloud-download"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="TaiLieuService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: TaiLieuService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #TaiLieuPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>