<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-shield-check fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Gửi thông báo</h2>
            <h6 class="m-0 text-dark fw-light">
                Điện thoại
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-Report" data-bs-toggle="tab" href="#Report"
                                    role="tab" aria-controls="Report" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Gửi Push Notification</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="Report" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tiêu đề</label>
                                            <input [(ngModel)]="ThanhVienThietBiService.baseParameter.Name" placeholder="Nhập tiêu đề ..."
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Nội dung</label>
                                            <input [(ngModel)]="ThanhVienThietBiService.baseParameter.Description" placeholder="Nhập nội dung ..."
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Hình ảnh</label>
                                            <input [(ngModel)]="ThanhVienThietBiService.baseParameter.FileName" placeholder="https://cms.fbs.coop66.net/assets/image/logo_coop66.png"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                (click)="PushNotification()"><i
                                                    class="bi bi-send"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="card mb-4 card-info">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Danh sách hình
                                                    <span
                                                        *ngIf="HinhAnhService.list">({{HinhAnhService.list.length}}
                                                        items)</span>
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table mat-table matSort #HinhAnhSort="matSort"
                                                            [dataSource]="HinhAnhService.DataSource"
                                                            class="table table-striped m-0">
                                                            <ng-container matColumnDef="STT">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    STT
                                                                </th>
                                                                <td mat-cell *matCellDef="let element; index as i;"
                                                                    style="text-align: center;">
                                                                    {{HinhAnhPaginator.pageSize *
                                                                    HinhAnhPaginator.pageIndex + i + 1}} [{{element.ID}}]
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="ID">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Hình ảnh
                                                                </th>
                                                                <td mat-cell *matCellDef="let element">
                                                                    <a target="_blank" href="{{element.FileName}}" *ngIf="element.FileName"
                                                                                    title="{{element.FileName}}">
                                                                                    <img class="responsive-img" width="100%" title="{{element.FileName}}"
                                                                                        alt="{{element.FileName}}" [src]="element.FileName"></a>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="FileName">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Hình ảnh
                                                                </th>
                                                                <td mat-cell *matCellDef="let element">
                                                                    <input readonly class="form-control" type="text" placeholder="URL"
                                                                    name="FileName{{element.ID}}" [(ngModel)]="element.FileName">
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="SortOrder">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                                </th>
                                                                <td mat-cell *matCellDef="let element">
                                                                    <input class="form-control" type="number" placeholder="0"
                                                                        name="SortOrder{{element.ID}}"
                                                                        [(ngModel)]="element.SortOrder"
                                                                        style="text-align: right;">
                                                                </td>
                                                            </ng-container>
                                                            
                                                            <ng-container matColumnDef="Code">
                                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Upload
                                                                </mat-header-cell>
                                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                                    <input type="file"
                                                                        (change)="changeFileName(element, $event.target.files)" style="width: 90px;">
                                                                </mat-cell>
                                                            </ng-container>
                                                            <ng-container matColumnDef="Save">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                </th>
                                                                <td mat-cell *matCellDef="let element">
                                                                    <a class="btn btn-info"
                                                                        (click)="HinhAnhSave(element)"><i
                                                                            class="bi bi-sd-card"></i>
                                                                    </a>&nbsp;&nbsp;&nbsp;
                                                                    <a class="btn btn-danger"
                                                                        (click)="HinhAnhDelete(element)"
                                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                    </a>
                                                                </td>
                                                            </ng-container>
                                                            <tr mat-header-row
                                                                *matHeaderRowDef="HinhAnhService.displayColumns001">
                                                            </tr>
                                                            <tr mat-row
                                                                *matRowDef="let row; columns: HinhAnhService.displayColumns001;">
                                                            </tr>
                                                        </table>
                                                        <mat-paginator #HinhAnhPaginator="matPaginator"
                                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                                            [showFirstLastButtons]></mat-paginator>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="ReportService.baseParameter.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ReportSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ReportSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span *ngIf="ReportService.list">({{ReportService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table  mat-table matSort
                                                    #ReportSort="matSort"
                                                    [dataSource]="ReportService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="ReportPaginator">
                                                                {{ReportPaginator.pageSize *
                                                                ReportPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PushNotificationTieuDe">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            
                                                            <a style="cursor: pointer;" title="{{element.PushNotificationTieuDe}}"
                                                                    class="link-primary"
                                                                    (click)="ThanhVienLichSuThongBaoView(element.PushNotificationTypeName)"><b>{{element.PushNotificationTieuDe}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PushNotificationNoiDung">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nội dung</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <textarea disabled  class="form-control" placeholder="Tiêu đề" name="PushNotificationNoiDung{{element.ID}}" [(ngModel)]="element.PushNotificationNoiDung"></textarea>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PushNotificationTongTinDaGui">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tổng gửi
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.PushNotificationTongTinDaGui}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PushNotificationTongTinDaNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tổng nhận 
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.PushNotificationTongTinDaNhan}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsPushNotification0000001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsPushNotification0000001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator
                                                    #ReportPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ReportService.IsShowLoading"></app-loading>