<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-gear fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Gửi mail</h2>
            <h6 class="m-0 text-dark fw-light">
                Thành viên
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVienThongBao" data-bs-toggle="tab"
                                    href="#ThanhVienThongBao" role="tab" aria-controls="ThanhVienThongBao"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Gửi mail</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVienThongBao" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="ThanhVienThongBaoService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ThanhVienThongBaoSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ThanhVienThongBaoSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="ThanhVienThongBaoService.list">({{ThanhVienThongBaoService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ThanhVienThongBaoSort="matSort"
                                                    [dataSource]="ThanhVienThongBaoService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ThanhVienThongBaoPaginator.pageSize *
                                                            ThanhVienThongBaoPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thành viên
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <mat-select *ngIf="element.ID==0" class="form-control" name="ParentID"
                                                                [(ngModel)]="element.ParentID">
                                                                <input class="form-control"
                                                                    (keyup)="ThanhVienFilter($event.target.value)">
                                                                <mat-option
                                                                    *ngFor="let item of ThanhVienService.list001"
                                                                    [value]="item.ID">
                                                                    {{item.Name}} - {{item.Email}} [{{item.ID}}]
                                                                </mat-option>
                                                            </mat-select>
                                                            <div *ngIf="element.ID>0">
                                                                {{element.Name}} - {{element.Code}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucDuAnID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Dự án
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select *ngIf="element.ID==0" class="form-select" name="DanhMucDuAnID"
                                                                [(ngModel)]="element.DanhMucDuAnID">
                                                                <option *ngFor="let item of DanhMucDuAnService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}} [{{item.ID}}]</option>
                                                            </select>
                                                            <select *ngIf="element.ID>0" disabled class="form-select" name="DanhMucDuAnID"
                                                            [(ngModel)]="element.DanhMucDuAnID">
                                                            <option *ngFor="let item of DanhMucDuAnService.list;"
                                                                [value]="item.ID">
                                                                {{item.Name}} [{{item.ID}}]</option>
                                                        </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ThanhVienThongBaoSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ThanhVienThongBaoDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienThongBaoService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienThongBaoService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ThanhVienThongBaoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>