import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';

import { ThanhVienPhanQuyenChucNang } from 'src/app/shared/ThanhVienPhanQuyenChucNang.model';
import { ThanhVienPhanQuyenChucNangService } from 'src/app/shared/ThanhVienPhanQuyenChucNang.service';

@Component({
  selector: 'app-thanh-vien-phan-quyen-chuc-nang',
  templateUrl: './thanh-vien-phan-quyen-chuc-nang.component.html',
  styleUrls: ['./thanh-vien-phan-quyen-chuc-nang.component.css']
})
export class ThanhVienPhanQuyenChucNangComponent implements OnInit {

  @ViewChild('ThanhVienPhanQuyenChucNangSort') ThanhVienPhanQuyenChucNangSort: MatSort;
  @ViewChild('ThanhVienPhanQuyenChucNangPaginator') ThanhVienPhanQuyenChucNangPaginator: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;
  activeAll: boolean = false;

  constructor(
    public NotificationService: NotificationService,

    public DanhMucThanhVienService: DanhMucThanhVienService,
    public ThanhVienPhanQuyenChucNangService: ThanhVienPhanQuyenChucNangService,
  ) { }

  ngOnInit(): void {
    this.DanhMucThanhVienGetToList();
  }

  DanhMucThanhVienGetToList() {
    this.isShowLoading = true;
    this.DanhMucThanhVienService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucThanhVienService.list = (res as DanhMucThanhVien[]).sort((a, b) => (a.ParentID > b.ParentID ? 1 : -1));
        this.DanhMucThanhVienService.list = this.DanhMucThanhVienService.list.filter((item: any) =>
        item.ID !== environment.ThanhVienNongDanID 
        && item.ID !== environment.ThanhVienQuanTriID 
        );
        this.DanhMucThanhVienService.list001 = this.DanhMucThanhVienService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucThanhVienFilter(searchString: string) {
    this.DanhMucThanhVienService.Filter(searchString);
  }
  
  ThanhVienPhanQuyenChucNangSearch() {
    //this.activeAll = false;
    if (this.ThanhVienPhanQuyenChucNangService.SearchString.length > 0) {
      this.ThanhVienPhanQuyenChucNangService.DataSource.filter = this.ThanhVienPhanQuyenChucNangService.SearchString.toLowerCase();
    }
    else {
      this.ThanhVienPhanQuyenChucNangGetToList();
    }
  }

  ThanhVienPhanQuyenChucNangGetToList() {
    this.isShowLoading = true;
    //this.ThanhVienPhanQuyenChucNangService.baseParameter.ParentID = this.parentID;
    this.ThanhVienPhanQuyenChucNangService.baseParameter.DanhMucThanhVienID = this.parentID;
    this.ThanhVienPhanQuyenChucNangService.GetSQLByDanhMucThanhVienIDToListAsync().subscribe(
      res => {
        this.ThanhVienPhanQuyenChucNangService.list = (res as ThanhVienPhanQuyenChucNang[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ThanhVienPhanQuyenChucNangService.DataSource = new MatTableDataSource(this.ThanhVienPhanQuyenChucNangService.list);
        this.ThanhVienPhanQuyenChucNangService.DataSource.sort = this.ThanhVienPhanQuyenChucNangSort;
        this.ThanhVienPhanQuyenChucNangService.DataSource.paginator = this.ThanhVienPhanQuyenChucNangPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienPhanQuyenChucNangActiveChange(element: ThanhVienPhanQuyenChucNang) {
    this.isShowLoading = true;
    this.ThanhVienPhanQuyenChucNangService.formData = element;
    //this.ThanhVienPhanQuyenChucNangService.formData.ParentID = this.parentID;
    this.ThanhVienPhanQuyenChucNangService.formData.DanhMucThanhVienID = this.parentID;
    this.ThanhVienPhanQuyenChucNangService.SaveAsync().subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienPhanQuyenChucNangActiveAllChange() {
    this.isShowLoading = true;
    for (let i = 0; i < this.ThanhVienPhanQuyenChucNangService.list.length; i++) {
      this.ThanhVienPhanQuyenChucNangService.formData = this.ThanhVienPhanQuyenChucNangService.list[i];
      //this.ThanhVienPhanQuyenChucNangService.formData.ParentID = this.parentID;
      this.ThanhVienPhanQuyenChucNangService.formData.DanhMucThanhVienID = this.parentID;
      this.ThanhVienPhanQuyenChucNangService.formData.Active = this.activeAll;     
    }
    this.ThanhVienPhanQuyenChucNangService.SaveListAsync(this.ThanhVienPhanQuyenChucNangService.list).subscribe(
      res => {
        this.ThanhVienPhanQuyenChucNangSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );   
  }
}
