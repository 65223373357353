import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NotificationService } from 'src/app/shared/Notification.service';

import { BaiViet } from 'src/app/shared/BaiViet.model';
import { BaiVietService } from 'src/app/shared/BaiViet.service';

import { DanhMucBaiViet } from 'src/app/shared/DanhMucBaiViet.model';
import { DanhMucBaiVietService } from 'src/app/shared/DanhMucBaiViet.service';

@Component({
  selector: 'app-bai-viet-detail',
  templateUrl: './bai-viet-detail.component.html',
  styleUrls: ['./bai-viet-detail.component.css']
})
export class BaiVietDetailComponent implements OnInit {

  isShowLoading: boolean = false;
  queryString: string = environment.InitializationString;
  searchString: string = environment.InitializationString;

  

  constructor(
    public dialogRef: MatDialogRef<BaiVietDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public BaiVietService: BaiVietService,    

    public DanhMucBaiVietService: DanhMucBaiVietService,
  ) { }

  ngOnInit(): void {
    
    this.DanhMucBaiVietGetToList();
  }
  ngOnDestroy(): void {
    
  }

  DanhMucBaiVietGetToList(){
    if (this.DanhMucBaiVietService.list.length == 0) {
      this.isShowLoading = true;
      this.DanhMucBaiVietService.baseParameter.Active = true;
      this.DanhMucBaiVietService.GetAllToList().subscribe(
        res => {
          this.DanhMucBaiVietService.list = (res as DanhMucBaiViet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.DanhMucBaiVietService.list001 = this.DanhMucBaiVietService.list;
          if (this.DanhMucBaiVietService.list) {
            if (this.DanhMucBaiVietService.list.length) {
              if(this.BaiVietService.formData.ParentID ==null){
                this.BaiVietService.formData.ParentID = this.DanhMucBaiVietService.list[0].ID;
              }
            }
          }        
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
    
  }

  BaiVietSave() {
    this.isShowLoading = true;
    this.BaiVietService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.BaiVietService.formData = res as BaiViet;
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  BaiVietNew() {
    this.isShowLoading = true;
    this.BaiVietService.formData.ID = 0;
    this.BaiVietService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.BaiVietService.formData = res as BaiViet;
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }

  Close() {
    this.dialogRef.close();
  }
  NgayGhiNhanChange(value) {
    this.BaiVietService.formData.NgayGhiNhan = new Date(value);
  }  
  NgayBatDauChange(value) {
    this.BaiVietService.formData.NgayBatDau = new Date(value);
  }  
  NgayKetThucChange(value) {
    this.BaiVietService.formData.NgayKetThuc = new Date(value);
  }  

  ChangeFileName(files: FileList) {

    if (files) {
      if (files.length > 0) {
        this.BaiVietService.fileToUpload = files;
        let fileToUpload0: File = null;
        fileToUpload0 = files.item(0);
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.BaiVietService.formData.FileName = event.target.result;
        };
        reader.readAsDataURL(fileToUpload0);
      }
    }
  }
}