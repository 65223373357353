<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bar-chart fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Dashboard</h2>
            <h6 class="m-0 text-dark fw-light">
                Báo cáo thống kê
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-3 card-info">
                <div class="card-header">
                    <h5 class="card-title text-white">Phiếu kế hoạch</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">
                                {{ReportService.formData.TongPhieuKeHoachVuMuaDongXuan |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Vụ mùa đông xuân</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-3 card-danger">
                <div class="card-header">
                    <h5 class="card-title text-white">Phiếu kế hoạch</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.TongPhieuKeHoachVuMuaHeThu |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Vụ mùa hè thu</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-3 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">Phiếu kế hoạch</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">
                                {{ReportService.formData.TongPhieuKeHoachVuMuaThuDong |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Vụ mùa thu đông</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-3 card-primary">
                <div class="card-header">
                    <h5 class="card-title text-white bg-primary">Phiếu kế hoạch</h5>
                </div>
                <div class="card-body bg-primary">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.TongPhieuKeHoachVuMuaKhac |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Vụ khác</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-3 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">Nông dân đăng ký</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.TongThanhVienNongDan |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Tổng tài khoản đăng ký</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-3 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">Nông dân xác thực</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.TongThanhVienNongDanXacThuc
                                |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Tổng tài khoản đã xác thực</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row gx-4">
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-3 card-info">
                <div class="card-header">
                    <h5 class="card-title text-white">Phiếu thực tế</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.TongPhieuThucTeVuMuaDongXuan
                                |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Vụ mùa đông xuân</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-3 card-danger">
                <div class="card-header">
                    <h5 class="card-title text-white">Phiếu thực tế</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.TongPhieuThucTeVuMuaHeThu |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Vụ mùa hè thu</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-3 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">Phiếu thực tế</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold"> {{ReportService.formData.TongPhieuThucTeVuMuaThuDong
                                |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Vụ mùa thu đông</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-3 card-primary">
                <div class="card-header">
                    <h5 class="card-title text-white bg-primary">Phiếu thực tế</h5>
                </div>
                <div class="card-body bg-primary">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.TongPhieuThucTeVuMuaKhac |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Vụ khác</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row gx-4">
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-3 card-info">
                <div class="card-header">
                    <h5 class="card-title text-white">Phiếu kết quả</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.TongPhieuKetQuaVuMuaDongXuan |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Vụ mùa đông xuân</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-3 card-danger">
                <div class="card-header">
                    <h5 class="card-title text-white">Phiếu kết quả</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.TongPhieuKetQuaVuMuaHeThu |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Vụ mùa hè thu</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-3 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">Phiếu kết quả</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold"> {{ReportService.formData.TongPhieuKetQuaVuMuaThuDong |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Vụ mùa thu đông</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-3 card-primary">
                <div class="card-header">
                    <h5 class="card-title text-white bg-primary">Phiếu kết quả</h5>
                </div>
                <div class="card-body bg-primary">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.TongPhieuKetQuaVuMuaKhac |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Vụ khác</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <h3 class="text-center" style="text-transform: uppercase;">Danh sách tài khoản đăng ký theo tỉnh</h3>
            <canvas style="font-size: 30px;" baseChart [datasets]="ChartDataReport1002" [labels]="ChartLabelsReport1002"
                [chartType]="ChartTypeReport1002" [colors]="ChartColorsReport1002" [options]="ChartOptionsReport1002">
            </canvas>
        </div>
        <div class="col-lg-12 col-sm-12 col-12">
            <h3 class="text-center" style="text-transform: uppercase;">Danh sách tài khoản đăng ký theo tỉnh</h3>
            <div class="table-responsive">
                <table class="table m-0 align-middle">
                    <thead>
                        <tr>
                            <th style="text-align: center;">STT</th>
                            <th style="text-align: center;">Tỉnh thành</th>
                            <th style="text-align: center;">Tổng nông dân</th>
                            <th style="text-align: center;">Đã xác thực</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of ReportService.list0000000000002; index as i;">
                            <td style="text-align: center;">
                                {{i + 1}}
                            </td>
                            <td>
                                <b>{{item.DanhMucTinhThanhName}}</b>
                            </td>
                            <td style="text-align: center;">
                                {{item.TongThanhVienNongDan |
                                number:
                                '1.0-0'}}
                            </td>
                            <td style="text-align: center;">
                                {{item.TongThanhVienNongDanXacThuc |
                                number:
                                '1.0-2'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<br />
<app-loading *ngIf="isShowLoading"></app-loading>