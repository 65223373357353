import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienService extends BaseService {

    list: ThanhVien[] | undefined;
    list001: ThanhVien[] | undefined;
    formData!: ThanhVien;
    formDataLogin!: ThanhVien;

    list002: ThanhVien[] | undefined;
    list003: ThanhVien[] | undefined;
    listChuyenGia: ThanhVien[] | undefined;
    listQuanTri: ThanhVien[] | undefined;

    displayColumns001: string[] = ['STT', 'Name', 'TaiKhoan', 'MatKhau', 'Email', 'TypeName', 'Active', 'Save'];
    displayColumns002: string[] = ['STT', 'DanhMucTinhThanhName', 'Description', 'Note', 'Name', 'TaiKhoan', 'Email', 'Active'];
    displayColumns003: string[] = ['STT', 'DanhMucTinhThanhName', 'DanhMucQuanHuyenName', 'Name', 'TaiKhoan', 'Email', 'Active'];
    displayColumns004: string[] = ['STT', 'ID', 'Name', 'TaiKhoan', 'Description', 'Note', 'DanhMucTinhThanhName', 'Active'];

    displayColumns005: string[] = ['STT', 'DanhMucTinhThanhName', 'DanhMucQuanHuyenName', 'Name', 'TaiKhoan', 'Email', 'IsXacThuc'];

    displayColumns0021: string[] = ['STT', 'Description', 'Note', 'Name', 'TaiKhoan', 'Email', 'Active'];


    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThanhVien";
    }
    FilterFull(searchString: string) {
        console.log(searchString);
        if (searchString.length > 0) {
            searchString = searchString.toLocaleLowerCase();
            this.list001 = this.list.filter((item: any) =>
                (item.Email && item.Email.toLocaleLowerCase().indexOf(searchString) !== -1) || (item.TaiKhoan && item.TaiKhoan.toLocaleLowerCase().indexOf(searchString) !== -1));
        }
        else {
            this.list001 = this.list;
        }
    }
    GetLogin() {
        this.formDataLogin = {
        }
        this.formDataLogin.Name = localStorage.getItem(environment.ThanhVienHoTen);
        var lastUpdatedMembershipID = localStorage.getItem(environment.ThanhVienID);
        if (lastUpdatedMembershipID) {
            this.formDataLogin.ID = Number(lastUpdatedMembershipID);
        }
    }

    GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentIDOrSearchStringToListAsync() {
        if (this.baseParameter.ParentID) {
        }
        else {
            this.baseParameter.ParentID = environment.InitializationNumber;
        }
        let url = this.aPIURL + this.controller + '/GetByParentIDOrSearchStringToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByDanhMucDuAnIDOrSearchStringToListAsync() {
        if (this.baseParameter.DanhMucDuAnID) {
        }
        else {
            this.baseParameter.DanhMucDuAnID = environment.InitializationNumber;
        }
        let url = this.aPIURL + this.controller + '/GetByDanhMucDuAnIDOrSearchStringToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByDanhMucDuAnIDAndSearchStringToListAsync() {
        if (this.baseParameter.DanhMucDuAnID) {
        }
        else {
            this.baseParameter.DanhMucDuAnID = environment.InitializationNumber;
        }
        let url = this.aPIURL + this.controller + '/GetByDanhMucDuAnIDAndSearchStringToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

