import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thanh-vien-lich-su-thong-bao',
  templateUrl: './thanh-vien-lich-su-thong-bao.component.html',
  styleUrls: ['./thanh-vien-lich-su-thong-bao.component.css']
})
export class ThanhVienLichSuThongBaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
