import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { DanhMucDuAn } from 'src/app/shared/DanhMucDuAn.model';
import { DanhMucDuAnService } from 'src/app/shared/DanhMucDuAn.service';

import { TaiLieu } from 'src/app/shared/TaiLieu.model';
import { TaiLieuService } from 'src/app/shared/TaiLieu.service';

import { DanhMucTaiLieu } from 'src/app/shared/DanhMucTaiLieu.model';
import { DanhMucTaiLieuService } from 'src/app/shared/DanhMucTaiLieu.service';

import { DanhMucBaiViet } from 'src/app/shared/DanhMucBaiViet.model';
import { DanhMucBaiVietService } from 'src/app/shared/DanhMucBaiViet.service';

import { BaiViet } from 'src/app/shared/BaiViet.model';
import { BaiVietService } from 'src/app/shared/BaiViet.service';
import { BaiVietDetailComponent } from '../bai-viet-detail/bai-viet-detail.component';


@Component({
  selector: 'app-tai-lieu',
  templateUrl: './tai-lieu.component.html',
  styleUrls: ['./tai-lieu.component.css']
})
export class TaiLieuComponent implements OnInit {

  @ViewChild('TaiLieuSort') TaiLieuSort: MatSort;
  @ViewChild('TaiLieuPaginator') TaiLieuPaginator: MatPaginator;

  @ViewChild('BaiVietSort') BaiVietSort: MatSort;
  @ViewChild('BaiVietPaginator') BaiVietPaginator: MatPaginator;

  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  DanhMucTaiLieuID: number = environment.InitializationNumber;
  DanhMucBaiVietID: number = environment.InitializationNumber;


  constructor(
    private dialog: MatDialog,

    public DanhMucBaiVietService: DanhMucBaiVietService,
    public DanhMucTaiLieuService: DanhMucTaiLieuService,
    public NotificationService: NotificationService,
    public DanhMucDuAnService: DanhMucDuAnService,
    public TaiLieuService: TaiLieuService,
    public BaiVietService: BaiVietService,
  ) { }

  ngOnInit(): void {
    this.DanhMucTaiLieuGetToList();
    this.DanhMucBaiVietGetToList();
  }

  DanhMucBaiVietGetToList(){
    this.isShowLoading = true;
    this.DanhMucBaiVietService.baseParameter.Active = true;
    this.DanhMucBaiVietService.GetAllToList().subscribe(
      res => {
        this.DanhMucBaiVietService.list = (res as DanhMucBaiViet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucBaiVietService.list001 = this.DanhMucBaiVietService.list;
        if (this.DanhMucBaiVietService.list) {
          if (this.DanhMucBaiVietService.list.length) {
            this.DanhMucBaiVietID = this.DanhMucBaiVietService.list[0].ID;
          }
        }        
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  
  DanhMucTaiLieuGetToList(){
    this.isShowLoading = true;
    this.DanhMucTaiLieuService.baseParameter.Active = true;
    this.DanhMucTaiLieuService.GetAllToList().subscribe(
      res => {
        this.DanhMucTaiLieuService.list = (res as DanhMucTaiLieu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTaiLieuService.list001 = this.DanhMucTaiLieuService.list;
        if (this.DanhMucTaiLieuService.list) {
          if (this.DanhMucTaiLieuService.list.length) {
            this.DanhMucTaiLieuID = this.DanhMucTaiLieuService.list[0].ID;
          }
        }        
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }


  fileToUpload: any;
  fileToUpload0: File = null;
  changeFileName(element: TaiLieu, files: FileList) {
    if (files) {
      this.fileToUpload = files;
      this.fileToUpload0 = files.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        element.FileName = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload0);
    }
  }
  TaiLieuSearch() {
    this.TaiLieuService.baseParameter.ParentID = this.DanhMucTaiLieuID;
    this.TaiLieuService.SearchByParentID(this.TaiLieuSort, this.TaiLieuPaginator);
  }
  TaiLieuDelete(element: TaiLieu) {
    this.TaiLieuService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.TaiLieuService.ComponentDeleteAll(this.TaiLieuSort, this.TaiLieuPaginator));
  }
  TaiLieuSave(element: TaiLieu) {
    this.isShowLoading = true;
    element.ParentID = this.DanhMucTaiLieuID;
    this.TaiLieuService.formData = element;
    this.TaiLieuService.fileToUpload = this.fileToUpload;
    this.TaiLieuService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.fileToUpload = null;
        this.fileToUpload0 = null;
        this.TaiLieuSearch();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  BaiVietSearch() {
    this.BaiVietService.baseParameter.ParentID = this.DanhMucBaiVietID;
    this.BaiVietService.SearchByParentIDNotEmpty(this.BaiVietSort, this.BaiVietPaginator);
  }
  BaiVietDelete(element: BaiViet) {
    this.BaiVietService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.BaiVietService.ComponentDeleteAllNotEmpty(this.BaiVietSort, this.BaiVietPaginator));
  }
  BaiVietAdd(ID: any) {
    this.BaiVietService.baseParameter.ID = ID;
    this.BaiVietService.GetByIDAsync().subscribe(
      res => {
        this.BaiVietService.formData = res as BaiViet;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(BaiVietDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.BaiVietSearch();
        });
      },
      err => {
      }
    );
  }

}
