import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { ThanhVienThietBi } from 'src/app/shared/ThanhVienThietBi.model';
import { ThanhVienThietBiService } from 'src/app/shared/ThanhVienThietBi.service';

import { ThanhVienLichSuThongBao } from 'src/app/shared/ThanhVienLichSuThongBao.model';
import { ThanhVienLichSuThongBaoService } from 'src/app/shared/ThanhVienLichSuThongBao.service';

import { Report } from 'src/app/shared/Report.model';
import { ReportService } from 'src/app/shared/Report.service';
import { ThanhVienLichSuThongBaoViewComponent } from '../thanh-vien-lich-su-thong-bao-view/thanh-vien-lich-su-thong-bao-view.component';

import { HinhAnh } from 'src/app/shared/HinhAnh.model';
import { HinhAnhService } from 'src/app/shared/HinhAnh.service';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.css']
})
export class PushNotificationComponent implements OnInit {
  @ViewChild('ReportSort') ReportSort: MatSort;
  @ViewChild('ReportPaginator') ReportPaginator: MatPaginator;

  @ViewChild('HinhAnhSort') HinhAnhSort: MatSort;
  @ViewChild('HinhAnhPaginator') HinhAnhPaginator: MatPaginator;

  isShowLoading: boolean = false;
  hinhAnhCode: string = 'PushNotification';

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,

    public ThanhVienThietBiService: ThanhVienThietBiService,
    public ThanhVienLichSuThongBaoService: ThanhVienLichSuThongBaoService,

    public ReportService: ReportService,

    public HinhAnhService: HinhAnhService,

  ) { }

  ngOnInit(): void {
    this.ReportSearch();
    this.HinhAnhGetToList();
  }

  fileToUpload: any;
  fileToUpload0: File = null;
  changeFileName(element: HinhAnh, files: FileList) {
    if (files) {
      this.fileToUpload = files;
      this.fileToUpload0 = files.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        element.FileName = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload0);
    }
  }
  HinhAnhSearch() {
    if (this.HinhAnhService.SearchString.length > 0) {
      this.HinhAnhService.DataSource.filter = this.HinhAnhService.SearchString.toLowerCase();
    }
    else {
      this.HinhAnhGetToList();
    }
  }
  HinhAnhGetToList() {
    this.isShowLoading = true;
    this.HinhAnhService.baseParameter.Code = this.hinhAnhCode;
    this.HinhAnhService.GetByCodeAndEmptyToListAsync().subscribe(
      res => {
        this.HinhAnhService.list = (res as HinhAnh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1))
        //.filter(x => x.Code == this.hinhAnhCode) ;
        this.HinhAnhService.DataSource = new MatTableDataSource(this.HinhAnhService.list);
        this.HinhAnhService.DataSource.sort = this.HinhAnhSort;
        this.HinhAnhService.DataSource.paginator = this.HinhAnhPaginator;

        this.HinhAnhService.list001 = this.HinhAnhService.list.slice(1);
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );    
  }

  HinhAnhSave(element: HinhAnh) {
    this.isShowLoading = true;
    element.Code = this.hinhAnhCode;
    this.HinhAnhService.formData = element;
    this.HinhAnhService.fileToUpload = this.fileToUpload;
    console.log("this.HinhAnhService.fileToUpload:",this.HinhAnhService.fileToUpload);
    this.HinhAnhService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.fileToUpload = null;
        this.fileToUpload0 = null;
        this.HinhAnhSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }



  HinhAnhDelete(element: HinhAnh) {
    if (confirm(environment.DeleteConfirm)) {
      this.isShowLoading = true;
      this.HinhAnhService.baseParameter.ID = element.ID;
      this.HinhAnhService.RemoveAsync().subscribe(
        res => {
          this.HinhAnhSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }


  PushNotification(){
    this.ReportService.IsShowLoading = true;
    this.ThanhVienThietBiService.PushNotification().subscribe(
        res => {
            this.ReportService.IsShowLoading = false;
            this.NotificationService.warn(environment.SaveSuccess + " Đã gửi thông báo đến "+ res + " thiết bị");
            this.ThanhVienThietBiService.baseParameter.Name ="";
            this.ThanhVienThietBiService.baseParameter.Description ="";
            this.ThanhVienThietBiService.baseParameter.FileName ="";
            this.ReportSearch();
        },
        err => {
            this.ReportService.IsShowLoading = false;
            this.NotificationService.warn(environment.SaveNotSuccess);
        }
    );
    return environment.SaveSuccess;
  }

  ReportSearch() {
    this.SearchAll(this.ReportSort, this.ReportPaginator);
  }
  SearchAll(sort: MatSort, paginator: MatPaginator) {
    if (this.ReportService.baseParameter.SearchString.length > 0) {
        this.ReportService.baseParameter.SearchString = this.ReportService.baseParameter.SearchString.trim();
        this.ReportService.DataSource.filter = this.ReportService.baseParameter.SearchString.toLowerCase();
    }
    else {
        this.ReportPushNotification0000001Async(sort, paginator);
    }
  }
  ReportPushNotification0000001Async(sort: MatSort, paginator: MatPaginator) {
    this.ReportService.IsShowLoading = true;
    this.ReportService.ReportPushNotification0000001Async().subscribe(
        res => {
            this.ReportService.list = (res as any[]);
            //this.ReportService.list = (res as any[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
            //this.ReportService.listFilter = this.ReportService.list.filter(item => item.ID > 0);
            this.ReportService.DataSource = new MatTableDataSource(this.ReportService.list);
            this.ReportService.DataSource.sort = sort;
            this.ReportService.DataSource.paginator = paginator;
            this.ReportService.IsShowLoading = false;
        },
        err => {
            this.ReportService.IsShowLoading = false;
        }
    );
  }
  ThanhVienLichSuThongBaoView(typeName: string) {
    this.ThanhVienLichSuThongBaoService.baseParameter.SearchString = typeName;
    this.ThanhVienLichSuThongBaoService.baseParameter.TypeName = typeName;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = environment.DialogConfigWidth;
    dialogConfig.data = { ID: 0 };
    const dialog = this.dialog.open(ThanhVienLichSuThongBaoViewComponent, dialogConfig);
    dialog.afterClosed().subscribe(() => {
          
    });
  }
}
