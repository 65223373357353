export const environment = {
  production: false,
  DomainURL: "https://cms.fbs.coop66.net/",
  DomainDestination: "https://cms.fbs.coop66.net/#/",
  APIURL: "https://api.cms.fbs.coop66.net/api/v1/",
  APIRootURL: "https://api.cms.fbs.coop66.net/",
  APIReportURL: "https://api.report.fbs.coop66.net/api/v1/",
  APIReportRootURL: "https://api.report.fbs.coop66.net/",
  APIUploadURL: "https://api.upload.fbs.coop66.net/api/v1/",
  APIUploadRootURL: "https://api.upload.fbs.coop66.net/",
  LoginURL: "https://login.fbs.coop66.net/",
  Website: "https://fbs.coop66.net/",
  Image: "Image",
  Company: "Company",
  Membership: "Membership",
  Barcode: "Barcode",
  QRcode: "QRcode",
  OrderDelivery: "OrderDelivery",
  MapIcon: "https://bando.vungtrong.com/Image/HTX4_30.png",
  Download: "Download",
  IPRegistry: "https://ipv4.myexternalip.com/json",
  LoadingFile: "loading.gif",
  DialogConfigWidth: "80%",
  DialogConfigWidth60: "60%",
  InitializationString: "",
  InitializationNumber: 0,
  HopTacXaID: 1,
  DanhMucSanPhamID: 890,
  DanhMucDuAnID: 1,
  ThanhVienQuanTriID: 3,
  ThanhVienCoQuanQuanLyID: 2, 
  ThanhVienChuyenGiaID: 4, 
  ThanhVienNongDanID: 1, 
  DanhMucTinhThanhID: 58, 
  MapZoom: 8,  
  Latitude: 9.6728475, 
  Longitude: 105.518659, 
  MaptilerAPIKey: "6iFTqps4QVACLERa26MA",
  Token: "Token",
  ThanhVienParentID: "ThanhVienParentID",
  ThanhVienID: "ThanhVienID",
  ThanhVienHoTen: "ThanhVienHoTen",
  ThanhVienTaiKhoan: "ThanhVienTaiKhoan",
  ThanhVienFileName: "ThanhVienFileName",
  ZTong: "Z.Tổng",
  HTMLExtension: ".html",
  Homepage: "Homepage",
  UploadSuccess: "Upload thành công.",
  UploadNotSuccess: "Upload không thành công.",
  SaveSuccess: "Lưu thành công.",
  SaveNotSuccess: "Lưu không thành công.",
  DeleteConfirm: "Bạn muốn xóa dữ liệu này?",
  DeleteSuccess: "Xóa thành công.",
  DeleteNotSuccess: "Xóa không thành công.",
  LoginNotSuccess: "Đăng nhập không thành công.",
  UserNameExists: "Tài khoản đã tồn tại.",
  UserNameRequired: "UserName là bắt buộc.",
  HTX0001: "Hợp tác xã chưa có dữ liệu Năng lực sản xuất.",
};
