import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThongBaoDienThoai } from 'src/app/shared/ThongBaoDienThoai.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThongBaoDienThoaiService extends BaseService {

    ListChild: ThongBaoDienThoai[] | undefined;
    ListParent: ThongBaoDienThoai[] | undefined;

    displayColumns001: string[] = ['STT', 'Name', 'TypeName','BatDau','KetThuc','IsKhongKetThuc','IsPopUp', 'SortOrder', 'Active','ID','FileName', 'Save'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThongBaoDienThoai";
    }
}

