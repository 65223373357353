import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienLichSuThongBao } from 'src/app/shared/ThanhVienLichSuThongBao.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienLichSuThongBaoService extends BaseService{

    displayColumns001: string[] = ['STT', 'ID', 'Name', 'Description', 'Active', 'DaGuiThongBao'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThanhVienLichSuThongBao";
    }

    GetByFileNameToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByFileNameToListAsync';   
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

