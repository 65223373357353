<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bank2 fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Trục dữ liệu lớn Nông nghiệp Đồng Bằng Sông Cửu Long
            </h2>
            <h6 class="m-0 text-dark fw-light">
                Giới thiệu
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThongBaoDetail" data-bs-toggle="tab"
                                    href="#ThongBaoDetail" role="tab" aria-controls="ThongBaoDetail"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Giới thiệu</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThongBaoDetail" role="tabpanel">
                                <div class="row gx-4">
                                    
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <div class="lg-offset-1 col-lg-10 col-sm-12 col-10 offset-1">
                                            <img _ngcontent-serverapp-c234="" src="/assets/image/banner.jpg" class="img-fluid">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12 mt-5">
                                           <h3><b class="text-danger">1: Tổ chức Hợp tác và Phát triển Đức (GIZ)</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5><b>Địa chỉ: </b>Tầng 6, Hanoi Towers, Số 49 Đường Hai Bà Trưng, Hà Nội, Việt Nam</h5>
                                            <h5><b>Số điện thoại: </b> <a href="tel:+84919162764">0919162764</a></h5>
                                        </div>
                                        <hr/>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b class="text-success">2: Trung tâm Đổi mới Ngành Nông nghiệp và Lương thực (GIC) </b></h3>
                                         </div>
                                         <div class="col-lg-12 col-sm-12 col-12">
                                             <h5><b>Địa chỉ: </b>Tầng 6, Hanoi Towers, Số 49 Đường Hai Bà Trưng, Hà Nội, Việt Nam</h5>
                                             <h5><b>Số điện thoại: </b> <a href="tel:+84919162764">0919162764</a></h5>
                                         </div>
                                        <hr/>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b class="text-primary">3: Cục kinh tế hợp tác và Phát triển nông thôn</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5><b>Địa chỉ: </b>Số 02 Ngọc Hà, Ba Đình, Hà Nội, Việt Nam</h5>
                                            <h5><b>Số điện thoại: </b> <a href="tel:02438438794">024 384 387 94</a></h5>
                                            <h5><b>Email: </b> <a href="mailto:hcth.htx@mard.gov.vn; cucktht@gmail.com">hcth.htx@mard.gov.vn; cucktht@gmail.com</a></h5>
                                        </div>
                                        <hr/>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b class="text-warning">4: Đơn vị hỗ trợ kỹ thuật: Công Ty Cổ Phần Đầu Tư Digital Kingdom</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5><b>Địa chỉ: </b>Tầng 1, tòa nhà Packsimex, Số 52 Đông Du , Phường Bến Nghé, Quận 1, TP. Hồ Chí Minh, Việt Nam</h5>
                                            <h5><b>Số điện thoại: </b> <a href="tel:0902561183">0902 561 183</a></h5>
                                            <h5><b>Email: </b> <a href="mailto:info@dgk.vn">info@dgk.vn</a></h5>
                                            <h5><b>Website: </b> <a href="https://dgk.vn/" target="_blank" >https://dgk.vn/</a></h5>
                                        </div>                                  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>