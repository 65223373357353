<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVienLichSuThongBao" data-bs-toggle="tab"
                                    href="#ThanhVienLichSuThongBao" role="tab" aria-controls="ThanhVienLichSuThongBao"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Lịch sử thông báo</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                                    role="tab" aria-controls="Close" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVienLichSuThongBao" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="ThanhVienLichSuThongBaoService.baseParameter.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ThanhVienLichSuThongBaoSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ThanhVienLichSuThongBaoSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="ThanhVienLichSuThongBaoService.list">({{ThanhVienLichSuThongBaoService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort
                                                    #ThanhVienLichSuThongBaoSort="matSort"
                                                    [dataSource]="ThanhVienLichSuThongBaoService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="ThanhVienLichSuThongBaoPaginator">
                                                                {{ThanhVienLichSuThongBaoPaginator.pageSize *
                                                                ThanhVienLichSuThongBaoPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <!--
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>FCM Token</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Code}}
                                                        </td>
                                                    </ng-container>
                                                    -->
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Name}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Description">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nội dung
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Description}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đã gửi
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active" disabled>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DaGuiThongBao">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đã nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="DaGuiThongBao{{element.ID}}"
                                                                    [(ngModel)]="element.DaGuiThongBao" disabled>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienLichSuThongBaoService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienLichSuThongBaoService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator
                                                    #ThanhVienLichSuThongBaoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienLichSuThongBaoService.IsShowLoading"></app-loading>