import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DanhMucDuAn } from 'src/app/shared/DanhMucDuAn.model';
import { DanhMucDuAnService } from 'src/app/shared/DanhMucDuAn.service';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';


@Component({
  selector: 'app-thanh-vien-detail001',
  templateUrl: './thanh-vien-detail001.component.html',
  styleUrls: ['./thanh-vien-detail001.component.css']
})
export class ThanhVienDetail001Component implements OnInit {

  
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  activeAll: boolean = false;
  activeAllKhuVuc: boolean = false;
  danhMucTinhThanhID: number = environment.InitializationNumber;
  constructor(
    public dialogRef: MatDialogRef<ThanhVienDetail001Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DanhMucDuAnService: DanhMucDuAnService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucThanhVienService: DanhMucThanhVienService,

    public ThanhVienService: ThanhVienService,  

  ) {
  }

  ngOnInit(): void {
    this.DanhMucThanhVienGetToList();
    this.DanhMucTinhThanhGetToList();    
    this.DanhMucDuAnGetToList();
    this.ThanhVienGetByID();
  }

  ThanhVienGetByID() {
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.formData = res as DanhMucQuanHuyen;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ThanhVienSave() {
    this.isShowLoading = true;
    this.ThanhVienService.SaveAsync().subscribe(
      res => {
        this.ThanhVienService.formData = res as ThanhVien;
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienDetele() {
    if (confirm(environment.DeleteConfirm)) {
      this.isShowLoading = true;
      this.ThanhVienService.baseParameter.ID = this.ThanhVienService.formData.ID;
      this.ThanhVienService.RemoveAsync().subscribe(
        res => {
          this.NotificationService.warn(environment.DeleteSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }

  Close() {
    this.dialogRef.close();
  }

  DanhMucDuAnGetToList() {
    if (this.DanhMucDuAnService.list.length == 0) {
      this.isShowLoading = true;
      this.DanhMucDuAnService.GetAllToListAsync().subscribe(
        res => {
          this.DanhMucDuAnService.list = (res as DanhMucDuAn[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  DanhMucThanhVienGetToList() {
    if (this.DanhMucThanhVienService.list.length == 0) {
      this.isShowLoading = true;
      this.DanhMucThanhVienService.GetAllToListAsync().subscribe(
        res => {
          this.DanhMucThanhVienService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }

  DanhMucTinhThanhGetToList() {
    if (this.DanhMucTinhThanhService.list.length == 0) {
      this.isShowLoading = true;
      this.DanhMucTinhThanhService.baseParameter.Active = true;
      this.DanhMucTinhThanhService.GetByActiveToListAsync().subscribe(
        res => {
          this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
          this.DanhMucQuanHuyenGetToList();
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.ThanhVienService.formData.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.ThanhVienService.formData.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }

  
}