import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucQuyTrinhSanXuat } from 'src/app/shared/DanhMucQuyTrinhSanXuat.model';
import { DanhMucQuyTrinhSanXuatService } from 'src/app/shared/DanhMucQuyTrinhSanXuat.service';
import { DanhMucPhanDoanSanXuat } from 'src/app/shared/DanhMucPhanDoanSanXuat.model';
import { DanhMucPhanDoanSanXuatService } from 'src/app/shared/DanhMucPhanDoanSanXuat.service';



import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { ToChucQuyTrinhSanXuat } from 'src/app/shared/ToChucQuyTrinhSanXuat.model';
import { ToChucQuyTrinhSanXuatService } from 'src/app/shared/ToChucQuyTrinhSanXuat.service';
import { ToChucQuyTrinhSanXuatChiTiet } from 'src/app/shared/ToChucQuyTrinhSanXuatChiTiet.model';
import { ToChucQuyTrinhSanXuatChiTietService } from 'src/app/shared/ToChucQuyTrinhSanXuatChiTiet.service';
import { ToChucQuyTrinhSanXuatLichSu } from 'src/app/shared/ToChucQuyTrinhSanXuatLichSu.model';
import { ToChucQuyTrinhSanXuatLichSuService } from 'src/app/shared/ToChucQuyTrinhSanXuatLichSu.service';
import { ToChucQuyTrinhSanXuatDetailComponent } from '../to-chuc-quy-trinh-san-xuat-detail/to-chuc-quy-trinh-san-xuat-detail.component';





@Component({
  selector: 'app-to-chuc-quy-trinh-san-xuat',
  templateUrl: './to-chuc-quy-trinh-san-xuat.component.html',
  styleUrls: ['./to-chuc-quy-trinh-san-xuat.component.css'],    
})
export class ToChucQuyTrinhSanXuatComponent implements OnInit {

  ToChucQuyTrinhSanXuatDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatSort') ToChucQuyTrinhSanXuatSort: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatPaginator') ToChucQuyTrinhSanXuatPaginator: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort') ToChucQuyTrinhSanXuatChiTietSort: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator') ToChucQuyTrinhSanXuatChiTietPaginator: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource1: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort1') ToChucQuyTrinhSanXuatChiTietSort1: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator1') ToChucQuyTrinhSanXuatChiTietPaginator1: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource2: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort2') ToChucQuyTrinhSanXuatChiTietSort2: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator2') ToChucQuyTrinhSanXuatChiTietPaginator2: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource3: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort3') ToChucQuyTrinhSanXuatChiTietSort3: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator3') ToChucQuyTrinhSanXuatChiTietPaginator3: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource4: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort4') ToChucQuyTrinhSanXuatChiTietSort4: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator4') ToChucQuyTrinhSanXuatChiTietPaginator4: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource5: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort5') ToChucQuyTrinhSanXuatChiTietSort5: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator5') ToChucQuyTrinhSanXuatChiTietPaginator5: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource6: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort6') ToChucQuyTrinhSanXuatChiTietSort6: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator6') ToChucQuyTrinhSanXuatChiTietPaginator6: MatPaginator;

  ToChucQuyTrinhSanXuatChiTietDataSource7: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietSort7') ToChucQuyTrinhSanXuatChiTietSort7: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatChiTietPaginator7') ToChucQuyTrinhSanXuatChiTietPaginator7: MatPaginator;

  ToChucQuyTrinhSanXuatLichSuDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucQuyTrinhSanXuatLichSuSort') ToChucQuyTrinhSanXuatLichSuSort: MatSort;
  @ViewChild('ToChucQuyTrinhSanXuatLichSuPaginator') ToChucQuyTrinhSanXuatLichSuPaginator: MatPaginator;


  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  DanhMucTinhThanhID: number = environment.InitializationNumber;
  DanhMucQuanHuyenID: number = environment.InitializationNumber;
  DanhMucXaPhuongID: number = environment.InitializationNumber;
  ToChucID: number = environment.InitializationNumber;

  constructor(
    private dialog: MatDialog,
   


    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucQuyTrinhSanXuatService: DanhMucQuyTrinhSanXuatService,
    public DanhMucPhanDoanSanXuatService: DanhMucPhanDoanSanXuatService,


    public ToChucService: ToChucService,
    public ToChucQuyTrinhSanXuatService: ToChucQuyTrinhSanXuatService,
    public ToChucQuyTrinhSanXuatChiTietService: ToChucQuyTrinhSanXuatChiTietService,
    public ToChucQuyTrinhSanXuatLichSuService: ToChucQuyTrinhSanXuatLichSuService,

    
  ) { }

  ngOnInit(): void {
    this.DanhMucQuyTrinhSanXuatGetToList();
    this.DanhMucPhanDoanSanXuatGetToList();
    this.DanhMucTinhThanhGetToList();
    this.ToChucGetAllToList();
    this.ToChucQuyTrinhSanXuatSearch();
    this.ToChucQuyTrinhSanXuatLichSuSearch();    
  }

  ToChucQuyTrinhSanXuatSearchAll() {     
    this.ToChucQuyTrinhSanXuatChiTietSearch();
    this.ToChucQuyTrinhSanXuatChiTietSearch1();
    this.ToChucQuyTrinhSanXuatChiTietSearch2();
    this.ToChucQuyTrinhSanXuatChiTietSearch3();
    this.ToChucQuyTrinhSanXuatChiTietSearch4();
    this.ToChucQuyTrinhSanXuatChiTietSearch5();
    this.ToChucQuyTrinhSanXuatChiTietSearch6();
    this.ToChucQuyTrinhSanXuatChiTietSearch7();
  }

  ToChucQuyTrinhSanXuatChiTietSave(element: ToChucQuyTrinhSanXuatChiTiet) {
    this.ToChucQuyTrinhSanXuatChiTietService.formData = element;
    this.ToChucQuyTrinhSanXuatChiTietService.SaveAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatSearchAll();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  ToChucQuyTrinhSanXuatChiTietDelete(element: ToChucQuyTrinhSanXuatChiTiet) {
    if (confirm(environment.DeleteConfirm)) {
      this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ID = element.ID;
      this.ToChucQuyTrinhSanXuatChiTietService.RemoveAsync().subscribe(
        res => {
          this.ToChucQuyTrinhSanXuatSearchAll();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }

  ToChucQuyTrinhSanXuatChiTietSearch7() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList7();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList7() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ToChucID = this.ToChucID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucSanPhamID = environment.DanhMucSanPhamID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucQuyTrinhSanXuatPhanLoaiID = 7;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByToChucID_DanhMucSanPhamID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list007 = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource7 = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list007);
        this.ToChucQuyTrinhSanXuatChiTietDataSource7.sort = this.ToChucQuyTrinhSanXuatChiTietSort7;
        this.ToChucQuyTrinhSanXuatChiTietDataSource7.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator7;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatChiTietSearch6() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList6();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList6() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ToChucID = this.ToChucID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucSanPhamID = environment.DanhMucSanPhamID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucQuyTrinhSanXuatPhanLoaiID = 6;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByToChucID_DanhMucSanPhamID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list006 = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource6 = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list006);
        this.ToChucQuyTrinhSanXuatChiTietDataSource6.sort = this.ToChucQuyTrinhSanXuatChiTietSort6;
        this.ToChucQuyTrinhSanXuatChiTietDataSource6.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator6;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatChiTietSearch5() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList5();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList5() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ToChucID = this.ToChucID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucSanPhamID = environment.DanhMucSanPhamID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucQuyTrinhSanXuatPhanLoaiID = 5;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByToChucID_DanhMucSanPhamID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list005 = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource5 = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list005);
        this.ToChucQuyTrinhSanXuatChiTietDataSource5.sort = this.ToChucQuyTrinhSanXuatChiTietSort5;
        this.ToChucQuyTrinhSanXuatChiTietDataSource5.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator5;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatChiTietSearch4() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList4();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList4() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ToChucID = this.ToChucID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucSanPhamID = environment.DanhMucSanPhamID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucQuyTrinhSanXuatPhanLoaiID = 4;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByToChucID_DanhMucSanPhamID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list004 = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource4 = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list004);
        this.ToChucQuyTrinhSanXuatChiTietDataSource4.sort = this.ToChucQuyTrinhSanXuatChiTietSort4;
        this.ToChucQuyTrinhSanXuatChiTietDataSource4.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator4;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatChiTietSearch3() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList3();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList3() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ToChucID = this.ToChucID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucSanPhamID = environment.DanhMucSanPhamID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucQuyTrinhSanXuatPhanLoaiID = 3;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByToChucID_DanhMucSanPhamID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list003 = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource3 = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list003);
        this.ToChucQuyTrinhSanXuatChiTietDataSource3.sort = this.ToChucQuyTrinhSanXuatChiTietSort3;
        this.ToChucQuyTrinhSanXuatChiTietDataSource3.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator3;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatChiTietSearch2() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList2();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList2() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ToChucID = this.ToChucID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucSanPhamID = environment.DanhMucSanPhamID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucQuyTrinhSanXuatPhanLoaiID = 2;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByToChucID_DanhMucSanPhamID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list002 = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource2 = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list002);
        this.ToChucQuyTrinhSanXuatChiTietDataSource2.sort = this.ToChucQuyTrinhSanXuatChiTietSort2;
        this.ToChucQuyTrinhSanXuatChiTietDataSource2.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator2;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatChiTietSearch1() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList1();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList1() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ToChucID = this.ToChucID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucSanPhamID = environment.DanhMucSanPhamID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucQuyTrinhSanXuatPhanLoaiID = 1;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByToChucID_DanhMucSanPhamID_DanhMucQuyTrinhSanXuatPhanLoaiIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list001 = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource1 = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list001);
        this.ToChucQuyTrinhSanXuatChiTietDataSource1.sort = this.ToChucQuyTrinhSanXuatChiTietSort1;
        this.ToChucQuyTrinhSanXuatChiTietDataSource1.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator1;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatChiTietSearch() {
    this.ToChucQuyTrinhSanXuatChiTietGetToList();
  }

  ToChucQuyTrinhSanXuatChiTietGetToList() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ToChucID = this.ToChucID;
    this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.DanhMucSanPhamID = environment.DanhMucSanPhamID;
    this.ToChucQuyTrinhSanXuatChiTietService.GetByToChucID_DanhMucSanPhamIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatChiTietService.list = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatChiTietDataSource = new MatTableDataSource(this.ToChucQuyTrinhSanXuatChiTietService.list);
        this.ToChucQuyTrinhSanXuatChiTietDataSource.sort = this.ToChucQuyTrinhSanXuatChiTietSort;
        this.ToChucQuyTrinhSanXuatChiTietDataSource.paginator = this.ToChucQuyTrinhSanXuatChiTietPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }


  ToChucQuyTrinhSanXuatSearch() {
    this.ToChucQuyTrinhSanXuatGetToList();
  }
  ToChucQuyTrinhSanXuatGetToList() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatService.baseParameter.DanhMucSanPhamID = environment.DanhMucSanPhamID;
    this.ToChucQuyTrinhSanXuatService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ToChucQuyTrinhSanXuatService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ToChucQuyTrinhSanXuatService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ToChucQuyTrinhSanXuatService.baseParameter.SearchString = this.ToChucQuyTrinhSanXuatService.SearchString;
    this.ToChucQuyTrinhSanXuatService.GetByDanhMucSanPhamID_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongID_SearchStringAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatService.list = (res as ToChucQuyTrinhSanXuat[]).sort((a, b) => (a.ParentID > b.ParentID ? 1 : -1));
        for (let i = 0; i < this.ToChucQuyTrinhSanXuatService.list.length; i++) {
          for (let j = 0; j < this.ToChucService.list.length; j++) {
            if (this.ToChucQuyTrinhSanXuatService.list[i].ParentID == this.ToChucService.list[j].ID) {
              this.ToChucQuyTrinhSanXuatService.list[i].Code = this.ToChucService.list[j].Code;
              this.ToChucQuyTrinhSanXuatService.list[i].Name = this.ToChucService.list[j].Name;
              this.ToChucQuyTrinhSanXuatService.list[i].Display = this.ToChucService.list[j].DienThoai;
            }
          }
        }
        if (this.ToChucQuyTrinhSanXuatService.list) {
          if (this.ToChucQuyTrinhSanXuatService.list.length > 0) {
            this.ToChucID = this.ToChucQuyTrinhSanXuatService.list[0].ParentID;
          }
        }
        this.ToChucQuyTrinhSanXuatDataSource = new MatTableDataSource(this.ToChucQuyTrinhSanXuatService.list);
        this.ToChucQuyTrinhSanXuatDataSource.sort = this.ToChucQuyTrinhSanXuatSort;
        this.ToChucQuyTrinhSanXuatDataSource.paginator = this.ToChucQuyTrinhSanXuatPaginator;
        this.ToChucQuyTrinhSanXuatSearchAll();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucQuyTrinhSanXuatSave(element: ToChucQuyTrinhSanXuat) {
    this.ToChucQuyTrinhSanXuatService.formData = element;
    this.ToChucQuyTrinhSanXuatService.SaveAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  ToChucQuyTrinhSanXuatDelete(element: ToChucQuyTrinhSanXuat) {
    if (confirm(environment.DeleteConfirm)) {
      this.ToChucQuyTrinhSanXuatService.baseParameter.ID = element.ID;
      this.ToChucQuyTrinhSanXuatService.RemoveAsync().subscribe(
        res => {
          this.ToChucQuyTrinhSanXuatSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  ToChucQuyTrinhSanXuatAdd(ID: any) {
    this.ToChucQuyTrinhSanXuatService.baseParameter.ID = ID;
    this.ToChucQuyTrinhSanXuatService.GetByIDAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatService.formData = res as ToChucQuyTrinhSanXuat;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ToChucQuyTrinhSanXuatDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
      },
      err => {
      }
    );
  }
  ToChucQuyTrinhSanXuatExportExcel() {
    this.isShowLoading = true;
    this.DownloadService.baseParameter.DanhMucSanPhamID = environment.DanhMucSanPhamID;
    this.DownloadService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.DownloadService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.DownloadService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.DownloadService.baseParameter.SearchString = this.ToChucQuyTrinhSanXuatChiTietService.SearchString;
    this.DownloadService.ExportToChucQuyTrinhSanXuatToExcelAsync().subscribe(
      res => {
        console.log(res);
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucGetAllToList() {
    this.ToChucService.baseParameter.ParentID = environment.HopTacXaID
    this.ToChucService.ComponentGetByParentIDToListAsync();
  }

  ToChucFilter(searchString: string) {
    searchString = searchString.trim();
    this.ToChucService.Filter(searchString);
  }

  DanhMucQuyTrinhSanXuatGetToList() {
    this.DanhMucQuyTrinhSanXuatService.ComponentGetAllToListAsync();
  }

  DanhMucPhanDoanSanXuatGetToList() {
    this.DanhMucPhanDoanSanXuatService.ComponentGetAllToListAsync();
  }

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.baseParameter.Active = true;
    this.DanhMucTinhThanhService.GetSQLByThanhVienIDAndActiveToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length) {
            this.DanhMucTinhThanhID = this.DanhMucTinhThanhService.list[0].ID;
          }
        }
        this.DanhMucQuanHuyenGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
   this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetSQLByThanhVienIDAndActiveAndParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetSQLByThanhVienIDAndActiveAndParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
    this.ToChucQuyTrinhSanXuatSearch();
  }

  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }

  ToChucQuyTrinhSanXuatLichSuSearch() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatLichSuService.GetAllToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatLichSuService.list = (res as ToChucQuyTrinhSanXuatLichSu[]).sort((a, b) => (a.SortOrder < b.SortOrder ? 1 : -1));
        this.ToChucQuyTrinhSanXuatLichSuService.DataSource = new MatTableDataSource(this.ToChucQuyTrinhSanXuatLichSuService.list);
        this.ToChucQuyTrinhSanXuatLichSuService.DataSource.sort = this.ToChucQuyTrinhSanXuatLichSuSort;
        this.ToChucQuyTrinhSanXuatLichSuService.DataSource.paginator = this.ToChucQuyTrinhSanXuatLichSuPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
}
