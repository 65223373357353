import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { DanhMucComponent } from './danh-muc/danh-muc.component';
import { UploadComponent } from './upload/upload.component';
import { HopTacXaComponent } from './hop-tac-xa/hop-tac-xa.component';
import { DanhMucNguyenVatLieuComponent } from './danh-muc-nguyen-vat-lieu/danh-muc-nguyen-vat-lieu.component';
import { DoanhNghiepComponent } from './doanh-nghiep/doanh-nghiep.component';
import { ToChucQuyTrinhSanXuatComponent } from './to-chuc-quy-trinh-san-xuat/to-chuc-quy-trinh-san-xuat.component';
import { ReportComponent } from './report/report.component';
import { ThanhVienComponent } from './thanh-vien/thanh-vien.component';
import { NongDanComponent } from './nong-dan/nong-dan.component';
import { ThoiTietComponent } from './thoi-tiet/thoi-tiet.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MapComponent } from './map/map.component';
import { ToChucDetailComponent } from './to-chuc-detail/to-chuc-detail.component';
import { ToChucInfoComponent } from './to-chuc-info/to-chuc-info.component';
import { MapVungTrongComponent } from './map-vung-trong/map-vung-trong.component';
import { MapVungTrongInfoComponent } from './map-vung-trong-info/map-vung-trong-info.component';
import { MapToChucToaDoComponent } from './map-to-chuc-toa-do/map-to-chuc-toa-do.component';
import { ThongBaoComponent } from './thong-bao/thong-bao.component';
import { GioiThieuComponent } from './gioi-thieu/gioi-thieu.component';
import { ThongKeComponent } from './thong-ke/thong-ke.component';
import { MapHopTacXaComponent } from './map-hop-tac-xa/map-hop-tac-xa.component';
import { TuVanComponent } from './tu-van/tu-van.component';
import { HuongDanSuDungComponent } from './huong-dan-su-dung/huong-dan-su-dung.component';
import { PhanQuyenComponent } from './phan-quyen/phan-quyen.component';
import { ThanhVienPhanQuyenChucNangComponent } from './thanh-vien-phan-quyen-chuc-nang/thanh-vien-phan-quyen-chuc-nang.component';
import { ThanhVienPhanQuyenKhuVucComponent } from './thanh-vien-phan-quyen-khu-vuc/thanh-vien-phan-quyen-khu-vuc.component';
import { QuanLyComponent } from './quan-ly/quan-ly.component';
import { ThongKe001Component } from './thong-ke001/thong-ke001.component';
import { ChuyenGiaComponent } from './chuyen-gia/chuyen-gia.component';
import { TaiLieuComponent } from './tai-lieu/tai-lieu.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';
import { PushNotificationComponent } from './push-notification/push-notification.component';
import { ThongBaoDienThoaiComponent } from './thong-bao-dien-thoai/thong-bao-dien-thoai.component';
import { DanhMucBaiVietComponent } from './danh-muc-bai-viet/danh-muc-bai-viet.component';
import { ThanhVien001Component } from './thanh-vien001/thanh-vien001.component';
import { ThanhVienThongTinKichHoatComponent } from './thanh-vien-thong-tin-kich-hoat/thanh-vien-thong-tin-kich-hoat.component';
import { ThanhVienThongBaoComponent } from './thanh-vien-thong-bao/thanh-vien-thong-bao.component';
import { ThanhVien002Component } from './thanh-vien002/thanh-vien002.component';


const routes: Routes = [
  { path: '', redirectTo: '/Homepage', pathMatch: 'full' },
  {
    path: 'ThanhVienThongTin', component: ThanhVienThongTinComponent,
  },
  {
    path: 'ThanhVienThongTinKichHoat/:ID', component: ThanhVienThongTinKichHoatComponent,
  },
  {
    path: 'Homepage', component: ThanhVienThongTinComponent,
  },
  {
    path: 'Map', component: MapComponent,
  },
  {
    path: 'MapHopTacXa', component: MapHopTacXaComponent,
  },
  {
    path: 'MapVungTrong', component: MapVungTrongComponent,
  },
  {
    path: 'MapToChucToaDo', component: MapToChucToaDoComponent,
  },
  {
    path: 'DanhMuc', component: DanhMucComponent,
  },
  {
    path: 'DanhMucNguyenVatLieu', component: DanhMucNguyenVatLieuComponent,
  },
  {
    path: 'Upload', component: UploadComponent,
  },
  {
    path: 'HopTacXa', component: HopTacXaComponent,
  },
  {
    path: 'NongDan', component: NongDanComponent,
  },
  {
    path: 'ChuyenGia', component: ChuyenGiaComponent,
  },
  {
    path: 'DoanhNghiep', component: DoanhNghiepComponent,
  },
  {
    path: 'TuVan', component: TuVanComponent,
  },
  {
    path: 'TaiLieu', component: TaiLieuComponent,
  },
  {
    path: 'HuongDanSuDung', component: HuongDanSuDungComponent,
  },
  {
    path: 'ToChucQuyTrinhSanXuat', component: ToChucQuyTrinhSanXuatComponent,
  },
  {
    path: 'ThanhVien', component: ThanhVienComponent,
  },
  {
    path: 'ThanhVien001', component: ThanhVien001Component,
  },
  {
    path: 'ThanhVien002', component: ThanhVien002Component,
  },
  {
    path: 'QuanLy', component: QuanLyComponent,
  },
  {
    path: 'PhanQuyen', component: PhanQuyenComponent,
  },
  {
    path: 'ThanhVienPhanQuyenChucNang', component: ThanhVienPhanQuyenChucNangComponent,
  },
  {
    path: 'ThanhVienPhanQuyenKhuVuc', component: ThanhVienPhanQuyenKhuVucComponent,
  },
  {
    path: 'ThanhVienThongBao', component: ThanhVienThongBaoComponent,
  },
  {
    path: 'ThongBao', component: ThongBaoComponent,
  },
  {
    path: 'GioiThieu', component: GioiThieuComponent,
  },
  {
    path: 'Report', component: ReportComponent,
  },
  {
    path: 'Dashboard', component: DashboardComponent,
  },
  {
    path: 'ThongKe', component: ThongKeComponent,
  },
  {
    path: 'ThongKe001', component: ThongKe001Component,
  },
  {
    path: 'ToChucInfo/:ID', component: ToChucInfoComponent,
  },
  {
    path: 'VungTrongInfo/:ID', component: MapVungTrongInfoComponent,
  },
  {
    path: 'PushNotification', component: PushNotificationComponent,
  },
  {
    path: 'ThongBaoDienThoai', component: ThongBaoDienThoaiComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
